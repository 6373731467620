import useOnlineStatus from '@hooks/useOnlineStatus';
import { ErrorPage } from '@pages/ErrorPage';
import React, { ReactNode } from 'react';
import { Root } from '@service/reducers';
import { useSelector } from 'react-redux';
import Header from '../Header';
import { Toaster } from 'react-hot-toast';
import { useBreakpoints } from '@hooks/useBreakpoints';
import { ScrollToTop } from '../ScrollToTop';
import Footer from '../Footer';
import styles from '../App/App.module.scss';

type NetworkStatusWrapperProps = {
  children: ReactNode;
};

const NetworkStatusWrapper: React.FC<NetworkStatusWrapperProps> = ({ children }) => {
  const isOnline = useOnlineStatus();
  const internalError = useSelector((store: Root) => store.system.internalError);
  const backgroundColor = useSelector((store: Root) => store.system.backgroundColor);
  const { isMobile } = useBreakpoints();

  if (!isOnline || internalError) {
    return (
      <main className={styles[`main__bg-${backgroundColor}`]}>
        <Header />
        <Toaster containerStyle={{ top: isMobile ? 15 : 150 }} />
        <ErrorPage />
        <Footer />
        <ScrollToTop />
      </main>
    );
  }

  return <>{children}</>;
};

export default NetworkStatusWrapper;
