import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import getBalances from '@service/actions/getBalances';
import { product } from '@utils/productName';
import { HelmetHead } from '../headersComponents/HelmetHead';
import { LinkInfoView } from './view/PoolLinkInfoView';
import PoolSubheader from '@pages/headersComponents/PoolSubheader';
import { Root } from '@service/reducers';
import { useGeneratePostModal } from '@hooks/useGeneratePostModal';
import { PRODUCT_KIND } from '@utils/checkPathname';
import { getPoolLandings } from '@service/actions/getPoolLandings';
import { selectBalance, selectBrowserGraph, selectPoolLandings } from '@service/selectors/selectors';
import { LinkAcc } from '@service/reducers/balanceReducer';
import { rpc } from '../../backend/Rpc';
import Loader from '@shared/Loader';
import getPoolGraphData from '@service/actions/getPoolGraphData';
import getPoolLinkDetailInfo from '@service/actions/getPoolLinkDetailInfo';

export default function PoolLinkInfo() {
  const match = useRouteMatch<{ id: string }>();
  const dispatch = useDispatch();

  const browserGraph = useSelector((store: Root) => selectBrowserGraph(store));
  const pool = useSelector((store: Root) => store.poolStatistic);
  const balance = useSelector((store: Root) => selectBalance(store));
  const poolLandings = useSelector((store: Root) => selectPoolLandings(store));
  const isFirstLoad = useRef(true);
  const { isMobile } = useSelector((store: Root) => store.system);

  const { handleGeneratePostClick } = useGeneratePostModal(PRODUCT_KIND.Pool, { initialLink: Number(match.params.id) });
  useEffect(() => {
    !balance.get_balance && dispatch(getBalances());
  }, [dispatch, balance.get_balance]);
  useEffect(() => {
    !poolLandings.poolLandsGet && dispatch(getPoolLandings());
  }, [dispatch, poolLandings.poolLandsGet]);
  const fetchDataWithDiscreteness = (...updateFlags: [boolean, boolean, boolean]) =>
    dispatch(
      getPoolLinkDetailInfo(
        browserGraph.period_start,
        browserGraph.period_end,
        pool.linksDiscreteness,
        pool.registrationDiscreteness,
        pool.earningsDiscreteness,
        false,
        ...updateFlags
      )
    );
  useEffect(() => {
    if (!isFirstLoad.current) {
      fetchDataWithDiscreteness(true, false, false);
    }
  }, [pool.linksDiscreteness]);

  useEffect(() => {
    if (!isFirstLoad.current) {
      fetchDataWithDiscreteness(false, true, false);
    }
  }, [pool.registrationDiscreteness]);

  useEffect(() => {
    if (!isFirstLoad.current) {
      fetchDataWithDiscreteness(false, false, true);
    }
  }, [pool.earningsDiscreteness]);

  useEffect(() => {
    dispatch(
      getPoolLinkDetailInfo(
        browserGraph.period_start,
        browserGraph.period_end,
        pool.linksDiscreteness,
        pool.registrationDiscreteness,
        pool.earningsDiscreteness,
        true,
        false,
        false,
        false
      )
    );
    isFirstLoad.current = false;
  }, [dispatch, browserGraph.period_start, browserGraph.period_end]);

  useEffect(() => {
    dispatch(
      getPoolGraphData('purchased', browserGraph.period_start, browserGraph.period_end, pool.purchasedDiscreteness)
    );
  }, [browserGraph.period_start, browserGraph.period_end, pool.purchasedDiscreteness]);
  const { t } = useTranslation();

  const link = useMemo(
    () => balance.links.find((item) => item.id === Number(match.params.id)),
    [match.params.id, balance.links]
  );

  const handleSave = useCallback(async (link: LinkAcc) => {
    await rpc.transmit('users.me.update_link', {
      link_id: link.id,
      name: link.name || '',
      comment: link.comment || '',
    });
    dispatch(getBalances());
  }, []);

  if (!link) {
    return null;
  }

  if (!balance.get_balance) {
    return <Loader />;
  }

  return (
    <>
      <HelmetHead title={t('Link to share')} product={product.ct} />
      {!isMobile && <PoolSubheader />}
      <LinkInfoView
        link={link}
        onSave={handleSave}
        id={Number(match.params.id)}
        url={'https://pool.cryptobrowser.site/landing/?aid='}
        onGeneratePost={handleGeneratePostClick}
      />
    </>
  );
}
