import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { BrowserPromoLand } from '@service/reducers/browserPromoReducer';
import { Button, ButtonVariant, LandingCard, PromoSection } from '@shared/index';
import styles from './BrowserLandingsBlock.module.scss';
import { Root } from '@service/reducers';
import FooterPromoSection from '@shared/FooterPromoSection/FooterPromoSection';

export type BrowserLandingsBlockProps = {
  list: string[];
  links?: {
    id: string | number;
    name?: string | null;
    comment?: string | null;
  }[];
  type?: 'pool';
};

enum BrowserLandingsBlockTab {
  All = 'All',
  General = 'General',
  Video = 'Video',
  Mobile = 'Mobile',
  Simple = 'Simple',
}

const SHOW_COUNT = 6;

export default function BrowserLandingsBlock({ list, links, type }: BrowserLandingsBlockProps) {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(BrowserLandingsBlockTab.All);
  const [visible, setVisible] = useState({ from: 0, to: SHOW_COUNT });
  const browserLandings = useSelector<Root, BrowserPromoLand[]>((store) => store.browserPromo.land);
  const poolLandings = useSelector<Root, BrowserPromoLand[]>((store) => store.poolLandings.lands);
  const landings = type === 'pool' ? poolLandings : browserLandings;
  const listLand = useMemo(() => {
    if (activeTab === BrowserLandingsBlockTab.All) {
      return landings;
    }

    return landings.filter((item) => !!item.category.match(activeTab));
  }, [landings, activeTab]);
  const handleClick = useCallback(() => {
    visible.to < listLand.length
      ? setVisible(({ from, to }) => ({
          from: from + SHOW_COUNT,
          to: to + SHOW_COUNT,
        }))
      : setVisible({ from: 0, to: SHOW_COUNT });
  }, [visible, listLand.length]);
  const showAll = useCallback(() => {
    setVisible({ from: 0, to: landings.length });
  }, [landings]);
  const hideFooter = useMemo(() => visible.to - visible.from >= landings.length, [visible, landings]);
  return (
    <PromoSection
      title={t('Additional landing pages')}
      text={t(
        'You can use additional landing pages of different styles and sizes to invite people to your mining network. Select the most appropriate landing page to best suit your promotional content and type of traffic.'
      )}
      id="landings"
      Footer={<FooterPromoSection onOtherClick={handleClick} onAllClick={showAll} isHide={hideFooter} />}
    >
      <div className={styles.container}>
        {listLand.slice(visible.from, visible.to).map((item) => (
          <LandingCard
            links={links}
            key={item.id}
            src={item.image}
            srcSet={item.image2x ? `${item.image2x} 2x` : undefined}
            href={item.url}
            listTitle={t('Link ID')}
            list={list}
          />
        ))}
      </div>
    </PromoSection>
  );
}
