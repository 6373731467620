import checkUser from '@service/actions/checkUser';
import { getAuthTokens } from '@service/actions/getAuthTokens';
import { Root } from '@service/reducers';
import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

export const useAuth = (isGetUser?: boolean, isProtected?: boolean) => {
  const user = useSelector((store: Root) => store.user);
  const dispatch = useDispatch();

  useEffect(() => {
    if ((isGetUser || isProtected) && !user.tokens?.accessToken) {
      dispatch(getAuthTokens(!!isGetUser));
    }
  }, [user.tokens, dispatch, isGetUser, isProtected]);

  useEffect(() => {
    if ((isGetUser || isProtected) && user.tokens?.accessToken && !user.hasRequested) {
      dispatch(checkUser());
    }
  }, [dispatch, user.tokens, user.hasRequested, isGetUser, isProtected]);

  return user;
};
