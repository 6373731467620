import { useTranslation } from 'react-i18next';
import { Button, ButtonVariant } from '../Button/Button';
import styles from './ProductPromoBanner.module.scss';
import { ReactComponent as Arrow } from '@assets/colorless/arrow-right-sm.svg';
import { ReactComponent as Cross } from '@assets/colorless/cross.svg';
import React, { ReactNode, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { Picture } from '@shared/Picture/Picture';

export type ProductPromoBannerProps = {
  title?: string | React.ReactNode;
  text?: string | React.ReactNode;
  ButtonGroup?: React.ReactNode;
  link: string;
  image: ImageType;
  imageClass?: string;
  cookie: string;
  type?: 'small';
  btnText?: string;
  btnIcon?: ReactNode;
};

type ImageType = {
  srcMob?: string;
  srcSetMob?: string;
  src: string;
  srcSet: string;
};

export function ProductPromoBanner({
  title,
  text,
  link,
  image,
  imageClass,
  ButtonGroup,
  cookie,
}: ProductPromoBannerProps) {
  const { t } = useTranslation();
  const [hide, setHide] = useState(document.cookie.includes(cookie));
  const onClose = useCallback(() => {
    setHide(true);
    window.document.cookie = `${cookie} max-age=5184000;`;
  }, [cookie]);

  if (hide) {
    return <></>;
  }

  return (
    <div className={styles.container}>
      <Cross className={styles.close} onClick={onClose} />
      <div className={styles.content}>
        <Link to={{ pathname: link }} className={styles.title}>
          {title || t('Generate promo materials with your personal link')}
        </Link>
        <div className={styles.text}>
          {text ||
            t(
              "Ready-made banners, images of various sizes, and posts—we've taken care of everything. Use our promo materials, attract new users and send invitations to your friends and followers on social networks. Expand your mining network and increase your chances of winning."
            )}
        </div>
        {ButtonGroup ? (
          ButtonGroup
        ) : (
          <Button
            type="link"
            to={{ pathname: link }}
            variant={ButtonVariant.Primary}
            Icon={<Arrow color="#fff" />}
            iconPosition="right"
            size="medium"
            className={styles.button}
          >
            {t('View promo materials')}
          </Button>
        )}
      </div>
      <Link to={{ pathname: link }} className={styles.image_link}>
        <Picture
          imageMob={image.srcMob}
          imageMob2x={image.srcSetMob}
          imageDesktop={image.src}
          imageDesktop2x={image.srcSet}
          className={`${styles.image} ${imageClass}`}
          breakPoint={'1024px'}
          alt="promo"
        />
      </Link>
    </div>
  );
}
