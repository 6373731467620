import styles from './Top.module.scss';
import { Card } from '@shared/Card/Card';
import { useTranslation } from 'react-i18next';
import { ArrowRightSmSvg } from '@assets/colorless';
import { Tabs } from '@shared/Tabs/Tabs';
import { ReactComponent as CT } from '@assets/browser-icon.svg';
import { useEffect, useState } from 'react';
import {
  AllProductTabs,
  CommonProduct,
  CommonProductLinks,
  CommonProductState,
} from '@shared/CommonProduct/CommonProduct';
import { BROWSER_DASH, FARM_DASH, MAIN_ABOUT_URL, POOL_DASH } from '@utils/links';
import { FarmSmLogoSvg } from '@assets/index';
import { FarmProLogoSvg } from '@assets/colored';
import ProductFooter from '@pages/CommonDashboard/view/components/Top/ProductFooter';
import { PRODUCT_KIND } from '@utils/checkPathname';
import { Button } from '@shared/Button/Button';
import { jsColors } from '@utils/jsColors';
import { Link } from 'react-router-dom';
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';
import { Pagination, Keyboard } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import { useSelector } from 'react-redux';
import { Root } from '@service/reducers';
import cls from 'classnames';

export type CommonDashboardTopProps = CommonProductLinks;

export default function Top(props: CommonDashboardTopProps) {
  const { farmLinks, farmProLinks, browserLinks, farmPromos, browserPromos, poolPromos, poolLinks } = props;
  const { t } = useTranslation();
  const [tab, setTab] = useState(AllProductTabs.Link);
  const [state, setState] = useState<CommonProductState>({});
  const baseProps = { state, active: tab, setState };
  const { isMobile } = useSelector((store: Root) => store.system);
  const [controlledSwiper, setControlledSwiper] = useState<SwiperClass | null>(null);

  return (
    <Card
      className={styles.topCard}
      TitleElement={
        <div className={styles.header}>
          <Link to={{ pathname: MAIN_ABOUT_URL }} className={styles.header__product}>
            <span>{t('ProductTitle')}</span>
            <Button type="button" Icon={<ArrowRightSmSvg color={jsColors.blue} />} onlyIcon />
          </Link>
          <Tabs
            active={tab}
            setState={setTab}
            items={[
              { text: t('Links'), kind: AllProductTabs.Link },
              { text: t('Promo Codes'), kind: AllProductTabs.Promo },
            ]}
          />
        </div>
      }
      footer={isMobile && controlledSwiper && <SliderControls controlledSwiper={controlledSwiper} />}
    >
      {isMobile ? (
        <Swiper
          onSwiper={setControlledSwiper}
          className={styles.slider}
          slidesPerView={1}
          spaceBetween={20}
          style={{ padding: '0 15px 15px' }}
          modules={[Pagination, Keyboard]}
          keyboard={{
            enabled: true,
            onlyInViewport: false,
          }}
          loop={true}
          pagination={{
            clickable: true,
            el: '.swiper-pagination-custom',
            renderCustom: (_: SwiperClass, current: number, total: number) => {
              return Array.from({ length: total })
                .map(
                  (_, index) =>
                    `<span class="${cls('swiper-pagination-bullet', {
                      'swiper-pagination-bullet-active': index + 1 === current,
                    })}"></span>`
                )
                .join('');
            },
          }}
        >
          <SwiperSlide>
            <CommonProduct
              Icon={<CT />}
              index={'br'}
              title={'CryptoTab Browser'}
              url={BROWSER_DASH}
              text={t('ct-affiliate_about_text-edits.first.block.Product_Icon.45')}
              links={browserLinks}
              promoCodes={browserPromos}
              Footer={<ProductFooter productKind={PRODUCT_KIND.Browser} />}
              {...baseProps}
            />
          </SwiperSlide>
          <SwiperSlide>
            <CommonProduct
              Icon={<FarmSmLogoSvg width={48} height={48} />}
              title={'CT Farm'}
              index={'ctf'}
              url={FARM_DASH}
              text={t('ct-affiliate_about_text-edits.first.Product_Icon.84')}
              links={farmLinks}
              promoCodes={farmPromos}
              Footer={<ProductFooter productKind={PRODUCT_KIND.Farm} />}
              {...baseProps}
            />
          </SwiperSlide>
          <SwiperSlide>
            <CommonProduct
              index={'ctfp'}
              Icon={<FarmProLogoSvg />}
              title={'CT Farm Pro'}
              url={FARM_DASH}
              text={t('ct-affiliate_about_text-edits.first.block.Product_Icon.45')}
              links={farmProLinks}
              promoCodes={farmPromos}
              Footer={<ProductFooter productKind={PRODUCT_KIND.FarmPro} />}
              {...baseProps}
            />
          </SwiperSlide>
          <SwiperSlide>
            <CommonProduct
              index={'ctp'}
              Icon={<CT />}
              title={'CT Pool'}
              url={POOL_DASH}
              text={t('ct-affiliate_about_text-edits.first.ct_pool_small_card_description')}
              links={poolLinks}
              promoCodes={poolPromos}
              Footer={<ProductFooter productKind={PRODUCT_KIND.Pool} />}
              {...baseProps}
            />
          </SwiperSlide>
        </Swiper>
      ) : (
        <div className={styles.body}>
          <CommonProduct
            Icon={<CT />}
            index={'br'}
            title={'CryptoTab Browser'}
            url={BROWSER_DASH}
            text={t('ct-affiliate_about_text-edits.first.block.Product_Icon.45')}
            links={browserLinks}
            promoCodes={browserPromos}
            Footer={<ProductFooter productKind={PRODUCT_KIND.Browser} />}
            {...baseProps}
          />
          <CommonProduct
            Icon={<FarmSmLogoSvg width={48} height={48} />}
            title={'CT Farm'}
            index={'ctf'}
            url={FARM_DASH}
            text={t('ct-affiliate_about_text-edits.first.Product_Icon.84')}
            links={farmLinks}
            promoCodes={farmPromos}
            Footer={<ProductFooter productKind={PRODUCT_KIND.Farm} />}
            {...baseProps}
          />
          <CommonProduct
            index={'ctfp'}
            Icon={<FarmProLogoSvg />}
            title={'CT Farm Pro'}
            url={FARM_DASH}
            text={t('ct-affiliate_about_text-edits.first.block.Product_Icon.45')}
            links={farmProLinks}
            promoCodes={farmPromos}
            Footer={<ProductFooter productKind={PRODUCT_KIND.FarmPro} />}
            {...baseProps}
          />
          <CommonProduct
            index={'ctp'}
            Icon={<CT />}
            title={'CT Pool'}
            url={POOL_DASH}
            text={t('ct-affiliate_about_text-edits.first.ct_pool_small_card_description')}
            links={poolLinks}
            promoCodes={poolPromos}
            Footer={<ProductFooter productKind={PRODUCT_KIND.Pool} />}
            {...baseProps}
          />
        </div>
      )}
    </Card>
  );
}

const SliderControls = ({ controlledSwiper }: { controlledSwiper: SwiperClass }) => {
  useEffect(() => {
    controlledSwiper.update();
  }, [controlledSwiper]);

  return (
    <div className={styles.sliderControls}>
      <div className={cls(styles.pagination, 'swiper-pagination-custom')}></div>
    </div>
  );
};
