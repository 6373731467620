import FarmDashboardView from './view/FarmDashboardView';
import { FarmSubheader } from '../headersComponents/FarmSubheader';
import { useTranslation } from 'react-i18next';
import { HelmetHead } from '../headersComponents/HelmetHead';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createPromoCode } from '@service/actions/createPromoCode';
import { product } from '@utils/productName';
import { useNewsLoad } from '@hooks/useNewsLoad';
import { Root } from '@service/reducers';
import getPromos from '@service/actions/getPromos';
import getFarmClicks from '@service/actions/getFarmClicks';
import { Span } from '@shared/Graph/Tabs';
import { rootUser } from '@service/reducers/userReducer';
import { rootBrowserGraph } from '@service/reducers/browserStatsReducer';
import getFarmMiner from '@service/actions/getFarmMiner';
import { useGeneratePostModal } from '@hooks/useGeneratePostModal';
import { PRODUCT_KIND } from '@utils/checkPathname';
import { AllProductTabs } from '@shared/CommonProduct/CommonProduct';

export default function FarmDashboard() {
  const { t } = useTranslation();
  const promo = useSelector((store: Root) => store.promo);
  const store = useSelector((store: { user: rootUser; browserGraph: rootBrowserGraph }) => store);

  const dispatch = useDispatch();
  const { period_start, period_end } = useSelector((store: Root) => store.browserGraph);
  useEffect(() => {
    dispatch(getFarmClicks(period_start, period_end, Span.Day));
  }, [period_start, period_end]);

  useEffect(() => {
    store.user.id !== 0 &&
      dispatch(getFarmMiner(Span.Day, store.user.id, store.browserGraph.period_start, store.browserGraph.period_end));
  }, [dispatch, store.user.id, store.browserGraph.period_start, store.browserGraph.period_end]);

  const { handleGeneratePostClick } = useGeneratePostModal(PRODUCT_KIND.Farm, {
    timeoutDelay: 2000,
    initialTab: AllProductTabs.Promo,
    showTabs: false,
  });

  const handleGenerateCodeClick = useCallback(() => {
    if (promo.promo_get) {
      dispatch(createPromoCode());
      handleGeneratePostClick();
    }
  }, [promo.promo_get, dispatch]);

  useEffect(() => {
    if (!promo.promo_get && !promo.promo_request) {
      dispatch(getPromos());
    }
  }, [dispatch, promo.promo_get, promo.promo_request]);

  useNewsLoad();

  return (
    <>
      <HelmetHead title={t('Dashboard')} product={product.farm} />
      <FarmSubheader />
      <FarmDashboardView promo={promo.promos} createCode={handleGenerateCodeClick} />
    </>
  );
}
