import styles from './Header.module.scss';
import { ReactComponent as BurgerSVG } from '@assets/colorless/burger.svg';
import { useRef, useState } from 'react';
import useClickOutside from '@hooks/useClickOutside';
import cls from 'classnames';
import Sidebar from '../Sidebar/Sidebar';

export type CommonNavMenuProps = {
  className?: string;
};

export default function CommonNavMenu({ className }: CommonNavMenuProps) {
  const [showMenu, setShowMenu] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const onClose = () => setShowMenu(false);
  useClickOutside(ref, onClose, showMenu);
  return (
    <>
      <div className={cls([styles.menu, className])} data-item="menu" ref={ref}>
        <div className={`${styles.burger} ${showMenu && styles.active}`} onClick={() => setShowMenu(!showMenu)}>
          {/* <BurgerSVG /> */}
          <span></span>
        </div>
        <Sidebar show={showMenu} />
      </div>
    </>
  );
}
