import { useTranslation } from 'react-i18next';
import { mapStatus } from '@pages/BrowserBalance/view/components/mapStatus';
import { WithdrawStatus } from '@utils/transactionStatus';
import { WithdrawalPaymentService } from '../../../../backend/JsonRpcApi';
import { ReactComponent as EmailConfirmSvg } from '../assets/email-confirm.svg';
import { ReactComponent as TimeSvg } from '../assets/time.svg';
import { ReactComponent as CheckSvg } from '../assets/check.svg';
import { ReactComponent as CheckGreenSvg } from '../assets/check-green.svg';
import { ReactComponent as CrossCircleSvg } from '../assets/cross-circle.svg';

export const WithdrawalNote = ({ status, type }: { status: number; type?: WithdrawalPaymentService }) => {
  const { t } = useTranslation();
  const [, description] = mapStatus(status, t);

  switch (status) {
    case WithdrawStatus.created:
      return (
        <>
          <EmailConfirmSvg />
          <span>{t('Confirm_Email')}</span>
        </>
      );
    case WithdrawStatus.confirmed:
    case WithdrawStatus.reconfirmed:
      return (
        <>
          <TimeSvg />
          <span>{t('Wait_confirm')}</span>
        </>
      );
    case WithdrawStatus.approved:
      return (
        <>
          <CheckSvg />
          <span>{description}</span>
        </>
      );
    case WithdrawStatus.sent:
    case WithdrawStatus.processed:
      return (
        <>
          <TimeSvg />
          <span>{description}</span>
        </>
      );
    case WithdrawStatus.complete: {
      return (
        <>
          <CheckGreenSvg />
          <span>{description}</span>
        </>
      );
    }
    default:
      return (
        <>
          <CrossCircleSvg />
          {description}
        </>
      );
  }
};
