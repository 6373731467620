import React, { ChangeEvent, FC, FormEvent, useCallback, useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import styles from './SupportForm.module.scss';
import SupportInput, { SupportInputVariant } from '../component/SupportInput';
import SupportDownload from '../component/SupportDownload';
import { captcha } from '@utils/captcha';
import { Button, ButtonVariant } from '@shared/index';
import { FormStateDate } from '../../FormStateData';
import { validateEmail, validateMessage } from '../../validators';
import { parseFileData, readFile } from '../../parseFileData';
import { SupportError, SupportErrorVariant } from '../../SupportError';
import { ReactComponent as Arrow } from '@assets/colorless/arrowLeft.svg';
import { ReactComponent as Check } from '@assets/colorless/check.svg';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { rootUser } from '../../../../service/reducers/userReducer';

type SupportFormProps = {
  email: string;
  onSubmit: (formState: FormStateDate) => void;
  onBack: () => void;
  isSubmitting?: boolean;
};

export const SupportForm: FC<SupportFormProps> = (props) => {
  const { email, onSubmit, onBack, isSubmitting } = props;
  const googleRe = useRef<ReCAPTCHA>(null);
  const [isAgree, setAgree] = useState(false);
  const [showCheckbox, setShowCheckbox] = useState(false);
  const [formState, setFormState] = useState<FormStateDate>({
    email,
    message: '',
    attachments: [],
  });

  const [error, setError] = useState<SupportError>({
    error: '',
    variant: SupportErrorVariant.Default,
  });

  const user = useSelector((store: { user: rootUser }) => store.user);

  const isLogin = user.hasRequested;

  useEffect(() => {
    if (user.hasRequested) {
      setFormState((prev) => ({
        ...prev,
        user_id: user.id.toString(),
        email: user.email || '',
      }));
    }
  }, [user]);
  const validateCaptcha = useCallback(() => {
    if (!googleRe.current?.getValue()) {
      setError({
        error: 'Enter captcha',
        variant: SupportErrorVariant.Captcha,
      });
      return null;
    } else {
      console.log('Captcha is valid');
      setError({ error: '', variant: SupportErrorVariant.Default });
    }
  }, [googleRe.current]);
  const handleSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();

      if (!showCheckbox && !isLogin && !formState.user_id && !isAgree) {
        setShowCheckbox(true);
        return null;
      }

      if (!googleRe.current?.getValue()) {
        setError({
          error: 'Enter captcha',
          variant: SupportErrorVariant.Captcha,
        });
        return null;
      }

      const { isValid: isEmailValid, error: emailError } = validateEmail(formState.email);
      if (!isEmailValid) {
        return setError(emailError!);
      }

      const { isValid: isMessageValid, error: messageError } = validateMessage(formState.message);
      if (!isMessageValid) {
        return setError(messageError!);
      }

      onSubmit(Object.fromEntries(Object.entries(formState).filter(([_, value]) => value !== '')) as FormStateDate);
    },
    [onSubmit, formState, showCheckbox, isLogin, isAgree]
  );

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;

    if (name === 'user_id') {
      if ((value !== '' && !value.match(/^\d+$/)) || value.length > 9) {
        return;
      }
    }
    setFormState((prev) => ({
      ...prev,
      [name]: name === 'email' ? value.trim() : value,
    }));
  }, []);

  const handleFileChange = useCallback(async (file: File | null) => {
    if (!file) return;

    try {
      const fileAsBase64 = await readFile(file);
      const content = parseFileData(fileAsBase64 as string);

      setFormState((prev) => ({
        ...prev,
        attachments: [...prev.attachments, { filename: file.name, content }],
      }));
    } catch (e) {}
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.decoration}></div>
      <form className={styles.card} onSubmit={handleSubmit}>
        <div className={styles.title}>Contact Us</div>
        <div className={styles.body}>
          <div className={styles.content}>
            <div className={styles.text}>
              We are happy to answer any questions you may have so don't hesitate to contact us and we will get back to
              you as soon as possible.
            </div>
            <div className={styles.subtitle}>All support queries must be in English!</div>
            <SupportInput
              label="Email:*"
              variant={SupportInputVariant.Email}
              name={'email'}
              value={formState.email}
              onChange={handleChange}
              error={error}
            />
            <SupportInput
              label="Issue description:*"
              variant={SupportInputVariant.Text}
              name={'message'}
              value={formState.message}
              onChange={handleChange}
              error={error}
            />
            {!isLogin && (
              <>
                <SupportInput
                  label="User ID"
                  variant={SupportInputVariant.Default}
                  name={'user_id'}
                  value={formState.user_id || ''}
                  onChange={handleChange}
                />
                {!formState.user_id && (
                  <label
                    className={cn(
                      styles.text,
                      styles.checkbox,
                      showCheckbox && !isAgree && !formState.user_id && styles.checkboxRed
                    )}
                    htmlFor="agree"
                  >
                    <input checked={isAgree} onChange={() => setAgree((s) => !s)} id="agree" type="checkbox" />I
                    understand that without providing a User ID, the solution to my question will take longer.
                  </label>
                )}
              </>
            )}
            <SupportDownload onSetFile={handleFileChange} />
            <ReCAPTCHA sitekey={captcha} ref={googleRe} onChange={validateCaptcha} className={styles.captcha} />
            <div className={styles.error}>{error.variant === SupportErrorVariant.Captcha && error.error}</div>
          </div>
        </div>
        <div className={styles.buttons}>
          <Button onClick={onBack} type="button" size="medium" Icon={<Arrow />}>
            BACK
          </Button>
          <Button
            type="button"
            variant={ButtonVariant.Primary}
            size="medium"
            Icon={<Check />}
            disabled={isSubmitting || (!isLogin && !formState.user_id && !isAgree && showCheckbox)}
          >
            SUBMIT
          </Button>
        </div>
      </form>
    </div>
  );
};
