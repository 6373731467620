import { Span } from '@shared/Graph/Tabs';
import dayjs from 'dayjs';
import { Discreteness } from '../backend/JsonRpcApi';
import IntlFormatter from './IntlFormatter';
import i18next from 'i18next';

export const MOB_VISIBLE_BARS = 7;
export const VISIBLE_BARS = 19;

export const defaultXformat = (span: Span) => {
  return (timestamp: number) => {
    const isoDateString = new Date(timestamp).toISOString();
    if (span === Span.Month) {
      return IntlFormatter.formatDate(isoDateString, i18next.language, {
        day: undefined,
        month: '2-digit',
        year: '2-digit',
      }).toString();
    } else {
      return IntlFormatter.formatDate(isoDateString, i18next.language, {
        day: '2-digit',
        month: '2-digit',
        year: undefined,
      }).toString();
    }
  };
};

export const customYformat = (num: number): string => {
  const step = 1e-8; // Шаг
  const threshold = 1e-8; // Минимальный шаг для отображения

  // Всегда отображаем ноль
  if (num === 0) {
    return '0';
  }

  // Не отображать значения ниже заданного порога
  if (num < threshold) {
    return '';
  }
  if (num > 1e-6) {
    return `${IntlFormatter.formatNumber(num, i18next.language)}`;
  }
  // Округляем значение до ближайшего кратного шага
  const roundedNum = Math.round(num / step) * step;

  // Если округленное значение не совпадает с оригинальным, не отображаем его
  return Math.abs(num - roundedNum) < 1e-9
    ? `${IntlFormatter.formatNumber(roundedNum.toFixed(8), i18next.language)}`
    : '';
};

export const btcToSatoshi = (btc: number) => {
  return btc * 100000000;
};
export const span_to_discreteness = (span: Span): Discreteness => {
  if (span === Span.Month) {
    return 'months' as Discreteness;
  } else if (span === Span.Week) {
    return 'weeks' as Discreteness;
  }
  return 'days' as Discreteness;
};
export const GraphDateConfig = {
  [Span.Day]: {
    format: (period: string | number) => dayjs(period).format('DD.MM'),
    parse: (timestamp: number) => dayjs(timestamp).valueOf(),
  },
  [Span.Week]: {
    format: (period: string | number) => dayjs(period).startOf('week').format('DD.MM'),
    parse: (timestamp: number) => dayjs(timestamp).startOf('week').valueOf(),
  },
  [Span.Month]: {
    format: (period: string | number) => dayjs(period).startOf('month').format('MM.YYYY'),
    parse: (timestamp: number) => dayjs(timestamp).startOf('month').valueOf(),
  },
};
