import styles from './Banner.module.scss';
import { ProductPromoBanner } from '@shared/ProductPromoBanner/ProductPromoBanner';
import { CtSmLogoSvg, FarmSmLogoSvg } from '@assets/index';
import { Button } from '@shared/Button/Button';
import { BROWSER_PROMO, FARM_PROMO, MAIN_PROMO_URL, POOL_PROMO } from '@utils/links';
import { CtpLogoSvg } from '@assets/colored';
import { useSelector } from 'react-redux';
import { Root } from '@service/reducers';

const ButtonGroup = () => (
  <div className={styles.container}>
    <Button to={BROWSER_PROMO} noSvgColorized size="medium" Icon={<CtSmLogoSvg />} external={false} type="link">
      CT BROWSER
    </Button>
    <Button to={POOL_PROMO} noSvgColorized Icon={<CtpLogoSvg />} size="medium" external={false} type="link">
      CT POOL
    </Button>
    <Button to={FARM_PROMO} noSvgColorized Icon={<FarmSmLogoSvg />} size="medium" external={false} type="link">
      CT FARM
    </Button>
  </div>
);

export default function Banner() {
  const { isDesktop } = useSelector((store: Root) => store.system);

  return (
    <ProductPromoBanner
      link={MAIN_PROMO_URL}
      image={{
        srcMob: 'https://cdn.cryptobrowser.site/images/affiliate-redesign/all-promo/promo-all-mob.png',
        srcSetMob: 'https://cdn.cryptobrowser.site/images/affiliate-redesign/all-promo/promo-all-mob@2x.png',
        src: 'https://cdn.cryptobrowser.site/images/affiliate-redesign/all-promo/promo-all.png',
        srcSet: 'https://cdn.cryptobrowser.site/images/affiliate-redesign/all-promo/promo-all@2x.png',
      }}
      ButtonGroup={isDesktop && <ButtonGroup />}
      cookie="commonbanner=hide"
    />
  );
}
