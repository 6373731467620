import { NavLink, useLocation } from 'react-router-dom';
import { FARM_DASH, FARM_PROMO } from '@utils/links';
import styles from '../../components/Subheader/Subheader.module.scss';
import Subheader from '../../components/Subheader';
import { useTranslation } from 'react-i18next';
import usePromoLoad from '@hooks/usePromoLoad';
import { FarmSmLogoSvg } from '@assets/index';
import { config } from '@pages/headersComponents/config';
import cls from 'classnames';
import { FC } from 'react';

export const FarmSubheader: FC = () => {
  const { t } = useTranslation();
  const { promo } = usePromoLoad();
  const location = useLocation();
  return (
    <Subheader Icon={<FarmSmLogoSvg />}>
      {config.farm.map((f) => {
        if (f.url === FARM_PROMO && promo.promos.length === 0) {
          return (
            <a
              href={'#promo'}
              className={cls([styles.withHelp, location.pathname === FARM_DASH && styles.withHelp__none])}
              key={f.url}
            >
              {f.Icon}
              {t(f.text)}
              <span className={styles.withHelp__help}>{t('GENERATE CODE')}</span>
            </a>
          );
        } else {
          return (
            <NavLink exact key={f.url} to={{ pathname: f.url }} activeClassName={styles.active}>
              {f.Icon}
              {t(f.text)}
            </NavLink>
          );
        }
      })}
    </Subheader>
  );
};
