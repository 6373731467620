import React from 'react';
import { EyeSvg } from '@assets/colorless';
import { LazyImage } from '@shared/LazyImage/LazyImage';
import styles from './ImageOpen.module.scss';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
interface ImageOpenProps {
  href: string;
  src: string;
  srcSet: string;
  className?: string;
}
export default function ImageOpen(props: ImageOpenProps) {
  const { href, src, srcSet, className } = props;
  const { t } = useTranslation();
  return (
    <a className={cn([styles.image, className])} href={href} target="_blank">
      <LazyImage src={src} srcSet={srcSet} />
      <div className={styles.before}>
        <EyeSvg /> {t('showFullSize')}
      </div>
    </a>
  );
}
