import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import { product } from '@utils/productName';
import { HelmetHead } from '../headersComponents/HelmetHead';
import { LinkInfoView } from './view/BrowserLinkInfoView';
import { BrowserSubheader } from '@pages/headersComponents/BrowserSubheader';
import { Root } from '@service/reducers';
import { PRODUCT_KIND } from '@utils/checkPathname';
import { useGeneratePostModal } from '@hooks/useGeneratePostModal';
import { selectBalance, selectBrowserGraph, selectBrowserPromo } from '@service/selectors/selectors';
import { rpc } from '../../backend/Rpc';
import Loader from '@shared/Loader';
import { LinkAcc } from '@service/reducers/balanceReducer';
import getBalances from '@service/actions/getBalances';
import getBrowserLinkDetailInfo from '@service/actions/getBrowserLinkDetailInfo';
import getBrowserLand from '@service/actions/getBrowserLand';

export default function LinkInfo() {
  const match = useRouteMatch<{ id: string }>();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isFirstLoad = useRef(true);
  const balance = useSelector((store: Root) => selectBalance(store));
  const browserGraph = useSelector((store: Root) => selectBrowserGraph(store));
  const browserPromo = useSelector((store: Root) => selectBrowserPromo(store));
  const { isMobile } = useSelector((store: Root) => store.system);
  const {
    period_start,
    period_end,
    opensDiscreteness,
    installsDiscreteness,
    minersDiscreteness,
    earningsDiscreteness,
  } = browserGraph;

  useEffect(() => {
    if (!balance.get_balance) dispatch(getBalances());
  }, [dispatch, balance.get_balance]);

  useEffect(() => {
    !browserPromo.land_get && dispatch(getBrowserLand());
  }, [dispatch, browserPromo.land_get]);

  const fetchDataWithDiscreteness = (...updateFlags: [boolean, boolean, boolean, boolean]) =>
    dispatch(
      getBrowserLinkDetailInfo(
        period_start,
        period_end,
        opensDiscreteness,
        installsDiscreteness,
        minersDiscreteness,
        earningsDiscreteness,
        false,
        ...updateFlags
      )
    );

  useEffect(() => {
    if (!isFirstLoad.current) {
      fetchDataWithDiscreteness(true, false, false, false);
    }
  }, [opensDiscreteness]);

  useEffect(() => {
    if (!isFirstLoad.current) {
      fetchDataWithDiscreteness(false, true, false, false);
    }
  }, [installsDiscreteness]);

  useEffect(() => {
    if (!isFirstLoad.current) {
      fetchDataWithDiscreteness(false, false, true, false);
    }
  }, [minersDiscreteness]);

  useEffect(() => {
    if (!isFirstLoad.current) {
      fetchDataWithDiscreteness(false, false, false, true);
    }
  }, [earningsDiscreteness]);

  useEffect(() => {
    dispatch(
      getBrowserLinkDetailInfo(
        period_start,
        period_end,
        opensDiscreteness,
        installsDiscreteness,
        minersDiscreteness,
        earningsDiscreteness,
        true,
        false,
        false,
        false,
        false
      )
    );
    isFirstLoad.current = false;
  }, [dispatch, period_start, period_end]);

  const link = useMemo(
    () => balance.links.find((item) => item.id === Number(match.params.id)),
    [match.params.id, balance.links]
  );

  const { handleGeneratePostClick } = useGeneratePostModal(PRODUCT_KIND.Browser, {
    initialLink: Number(match.params.id),
  });

  const handleSave = useCallback(
    async (link: LinkAcc) => {
      await rpc.transmit('users.me.update_link', {
        link_id: link.id,
        name: link.name || '',
        comment: link.comment || '',
      });
      dispatch(getBalances());
    },
    [dispatch]
  );

  if (!link) return null;
  if (!balance.get_balance) return <Loader />;

  return (
    <>
      <HelmetHead title={t('Link to share')} />
      {!isMobile && <BrowserSubheader />}
      <LinkInfoView
        link={link}
        onSave={handleSave}
        id={Number(match.params.id)}
        url={'https://cryptotabbrowser.com/'}
        onGeneratePost={handleGeneratePostClick}
      />
    </>
  );
}
