import styles from './BackButton.module.scss';
import { FC, HTMLAttributes } from 'react';
import { ArrowLeftSmSvg } from '@assets/colorless';
import cls from 'classnames';
import { useGoBack } from '@hooks/useGoBack';

export function BackButton(props: HTMLAttributes<HTMLButtonElement>) {
  const { goBack } = useGoBack();
  return (
    <button type="button" {...props} onClick={goBack} className={cls(styles.back, props.className)}>
      <ArrowLeftSmSvg />
      {props.children}
    </button>
  );
}
