import { AppDispatch, rootStoreType } from '../../index';
import {
  GET_WITHDRAWALS_REQUEST,
  GET_WITHDRAWALS_SUCCESS,
  GET_WITHDRAWALS_ERROR,
  GET_WITHDRAWALS_NEXT,
  GET_WITHDRAWALS_NEXT_REQUEST,
  UPDATE_MOB_WITHDRAWALS,
} from '../constant';
import { WithdrawalsState } from '../reducers/withdrawalsReducer';
import { Withdrawal } from '../../backend/JsonRpcApi';
import { rpc } from '../../backend/Rpc';
import { updateDataToBTC } from '@utils/updateDataToBTC';

const MOCK_WITHDRAWALS: Withdrawal[] = [
  {
    id: 'f15ad712-e8b1-40c7-8783-37566c9f43f5',
    request_date: 1661948007,
    address: 'CMa7zZU8sVNOIWegPUaVu5yJHotMgYrpLe',
    amount: '0.00000829',
    state: 2,
    type: 'regular',
    transaction_id: '',
    payment_service: 'bitcoin',
  },
  {
    id: '2a70e750-7b9b-4275-b6e7-b7134e594710',
    request_date: 1661948007,
    address: '6LH9F70DPijGdT3NrbN1XSaAEU5j8UCSML',
    amount: '0.00005121',
    state: 9,
    type: 'regular',
    transaction_id: 'teteatat',
    payment_service: 'ncw',
  },
];

const perPage = 10;

const isDev = false; //!process.env.NODE_ENV || process.env.NODE_ENV === 'development'

export const getWithdrawals = (noLimit?: boolean) => async (dispatch: AppDispatch, getState: () => rootStoreType) => {
  try {
    dispatch({ type: GET_WITHDRAWALS_REQUEST });
    let res;

    const { isBitcoin }: { isBitcoin: boolean } = getState().balance;

    if (isBitcoin) {
      res = await rpc.transmit('withdrawals.get', noLimit ? {} : { limit: 6 });
    } else {
      const hshRes = await rpc.transmit('wallets.withdrawals.history', noLimit ? {} : { limit: 6 });
      res = { ...hshRes, items: hshRes.items.map((item) => updateDataToBTC(item)) };
    }

    const items = isDev
      ? res.items.length > 0
        ? res.items
        : MOCK_WITHDRAWALS?.map((item: Withdrawal) => ({
            ...item,
            request_date: item.request_date * 1000,
          }))
      : res.items?.map((item) => ({
          ...item,
          request_date: item.request_date * 1000,
        }));

    dispatch({
      type: GET_WITHDRAWALS_SUCCESS,
      withdrawals: items,
      limit: res.total,
    });

    const { withdrawals }: { withdrawals: WithdrawalsState } = getState();
    const { withdrawals: withdrawalsToShow, perPage } = withdrawals;

    const start = perPage * (1 - 1);
    const sliced = withdrawalsToShow.slice(start, perPage);

    dispatch({
      type: GET_WITHDRAWALS_NEXT,
      withdrawalsToShow: sliced,
      index: 0,
      total: res.total,
    });
  } catch (e) {
    dispatch({ type: GET_WITHDRAWALS_ERROR });
  }
};

export const getNextWithdrawals =
  (index = 1, _perPage = perPage, isMob = false) =>
  async (dispatch: AppDispatch, getState: () => rootStoreType) => {
    try {
      dispatch({ type: GET_WITHDRAWALS_NEXT_REQUEST });
      const { isBitcoin }: { isBitcoin: boolean } = getState().balance;
      let res;

      if (isBitcoin) {
        res = await rpc.transmit('withdrawals.get', {
          offset: (index - 1) * _perPage,
          limit: _perPage,
        });
      } else {
        let hshRes = await rpc.transmit('wallets.withdrawals.history', {
          offset: (index - 1) * perPage,
          limit: perPage,
        });

        res = { ...hshRes, items: hshRes.items.map((item) => updateDataToBTC(item)) };
      }

      const withdrawalsToShow = res.items?.map((item) => ({
        ...item,
        request_date: item.request_date * 1000,
      }));

      if (isMob) {
        dispatch({
          type: UPDATE_MOB_WITHDRAWALS,
          withdrawalsToShow,
          index: index - 1,
          total: res.total,
        });
      } else {
        dispatch({
          type: GET_WITHDRAWALS_NEXT,
          withdrawalsToShow,
          index: index - 1,
          total: res.total,
        });
      }
    } catch (e) {}
  };
