import styles from './EmailConfirmation.module.scss';
import { EmailSentIcon } from './assets';
import { Button, ButtonVariant } from '@shared/Button/Button';
import { EmailSvg } from '@assets/colorless';
import { Trans, useTranslation } from 'react-i18next';

type EmailConfirmationSentProps = {
  email: string;
  onGoBack: () => void;
};

export const EmailConfirmationSent = ({ email, onGoBack }: EmailConfirmationSentProps) => {
  const { t } = useTranslation();

  const onClickMailbox = () => {
    window.open('https://mail.google.com/', '_blank');
  };

  return (
    <div className={styles.container}>
      <div className={styles.contentContainer}>
        <div className={styles.iconContainer}>
          <EmailSentIcon />
        </div>
        <div className={styles.innerContainer}>
          <h1 className={styles.title}>{t('Aff.Verify.Title')}</h1>
          <p className={styles.description}>
            <Trans i18nKey="Aff.Verify.Description" components={{ b: <b /> }} values={{ email }} />
          </p>
          <div className={styles.additionalInfo}>
            <Trans i18nKey="Aff.Verify_conf.Descr" components={{ b: <b /> }} values={{ email }} />
          </div>
        </div>
      </div>

      <div className={styles.footer}>
        <div className={styles.buttonsGroup}>
          <Button className={styles.button} type={'button'} variant={ButtonVariant.Primary} onClick={onClickMailbox}>
            <EmailSvg /> {t('Aff.Verify_conf.Btn')}
          </Button>
        </div>
      </div>
    </div>
  );
};
