import styles from './NCWCheck.module.scss';
import cls from 'classnames';
import { ReactComponent as NcwLogo } from '@assets/colorless/ncw.svg';
import { ReactComponent as Wallet } from '@assets/colorless/wallet-add.svg';
import { ReactComponent as InfoIcon } from '@assets/colorless/info-icon.svg';
import { ReactComponent as Check } from '@assets/colorless/check.svg';
import { useTranslation } from 'react-i18next';
import { Button } from '@shared/index';

export type NCWCheckProps = {
  isCheck: boolean;
  showPromo: () => void;
};

export default function NCWCheck({ isCheck, showPromo }: NCWCheckProps) {
  const { t } = useTranslation();
  const containerClass = cls({
    [styles.container]: true,
    [styles.container__checked]: isCheck,
  });

  const textClass = cls({
    [styles.text]: true,
    [styles.text__checked]: isCheck,
  });
  return (
    <div className={containerClass}>
      {isCheck ? (
        <NcwLogo width={28} height={28} color="#37CC33" />
      ) : (
        <NcwLogo width={28} height={28} color="#757575" />
      )}
      <div className={textClass}>NC Wallet {isCheck ? t('Connected') : t('Not Connected')}</div>
      {isCheck ? (
        <Check width={16} height={16} color="#37CC33" className={styles.check} />
      ) : (
        <InfoIcon width={16} height={16} color="#F74249" />
      )}
      {!isCheck && (
        <Button className={styles.btn} type="button" onClick={showPromo} size="small" Icon={<Wallet />}>
          {t('Connect')}
        </Button>
      )}
    </div>
  );
}
