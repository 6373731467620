import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CopyText } from '../CopyText/CopyText';
import { ShareBlock } from '../ShareBlock/ShareBlock';
import styles from './TextCard.module.scss';
import { Button } from '@shared/Button/Button';
import cls from 'classnames';
import { ArrowBottomSmSvg, ShareSvg } from '@assets/colorless';
import useClickOutside from '@hooks/useClickOutside';

export type TextCardProps = {
  text: string;
  currentLinkId?: number;
  productKind?: string;
  handleCopy?: (e: React.ClipboardEvent<HTMLDivElement>) => void;
  url?: string;
  textForCopy?: string;
};

const MAX_LENGTH = 320;

export function TextCard({ text, currentLinkId, productKind, handleCopy, url, textForCopy }: TextCardProps) {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  return (
    <div className={`${styles.container} ${show && styles.container__show}`} onCopy={handleCopy}>
      <div className={styles.body}>
        <div className={styles.text}>
          <div
            className={styles.text}
            dangerouslySetInnerHTML={{
              __html: t(text)
                ? (show ? t(text) : t(text).slice(0, MAX_LENGTH) + '...').replace(
                    /{{ref_link}}/g,
                    `${productKind}${currentLinkId}`
                  )
                : t(text),
            }}
          ></div>
        </div>
        {t(text).length > MAX_LENGTH && (
          <div className={styles.more} onClick={() => setShow(!show)}>
            <span>{!show ? t('Learn more') : t('Show Less second')}</span>
            <ArrowBottomSmSvg className={cls([show && styles.active])} />
          </div>
        )}
      </div>
      <div className={styles.row}>
        <Share text={textForCopy ?? t(text)} url={url} />
        <CopyText isBtn text={t(text)} textForCopy={textForCopy} />
      </div>
    </div>
  );
}

const Share = ({ text, textForCopy, url }: { text: string; textForCopy?: string; url?: string }) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const ref = useRef<HTMLButtonElement>(null);
  useClickOutside(ref, () => setShow(false), show);

  return (
    <Button
      ref={ref}
      type="button"
      size="medium"
      className={styles.share}
      Icon={<ShareSvg />}
      onClick={() => setShow((prev) => !prev)}
    >
      {t('share')}
      {show && (
        <div className={styles.share__list} id="commonAboutShare">
          <ShareBlock msg={textForCopy ?? t(text)} size="l" limit={20} url={url} />
        </div>
      )}
    </Button>
  );
};
