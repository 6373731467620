import styles from './ModalPromo.module.scss';
import src from '@assets/wallet-modal.png';
import srcSet from '@assets/wallet-modal@2x.png';
import { useTranslation } from 'react-i18next';
import { Button, ButtonVariant } from '@shared/index';
import { ReactComponent as Wallet } from '@assets/colored/icon-wallet.svg';
import { ReactComponent as Close } from '@assets/colored/circle-close.svg';
import { useCallback } from 'react';
import { externalLinks } from '@utils/externalLinks';
import ModalLayout from '../../../../components/ModalLayout/ModalLayout';

export type ModalWalletProps = {
  onClose: () => void;
  link?: string;
};

export function ModalWallet({ onClose, link }: ModalWalletProps) {
  const { t } = useTranslation();
  const handleCreate = useCallback(() => {
    window.open(link || externalLinks.ncw);
    onClose();
  }, [onClose, link]);

  const { Title, Content, Footer, Description, Header, ModalLayoutForPromo, Button } = ModalLayout;

  return (
    <ModalLayoutForPromo onClose={onClose} isOpen={true}>
      <Header image>
        <div className={styles.imageWrapper}>
          <picture>
            <source srcSet={srcSet} media="(max-width: 767px)" />
            <img src={src} alt={t('PopUp1.Title')} className={styles.image} />
          </picture>
        </div>
      </Header>
      <Content align="left" padding="small">
        <Title size="small">
          <a href={externalLinks.ncw} target="_blank" className={styles.title}>
            {t('PopUp1.Title')}
          </a>
        </Title>
        <Description>{t('PopUp1.Description')}</Description>
      </Content>
      <Footer gray>
        <Button color="blue" onClick={handleCreate} wide icon={<Wallet />}>
          {t('CREATE WALLET')}
        </Button>
      </Footer>
    </ModalLayoutForPromo>
  );
}
