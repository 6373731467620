import { Container } from '@shared/Container/Container';
import { Card } from '@shared/Card/Card';
import { useTranslation } from 'react-i18next';
import { Pallet } from '@shared/Pallet/Pallet';
import styles from './CommonPromoView.module.scss';
import Product from '@pages/CommonPromo/view/Product';
import { BROWSER_PROMO, FARM_PROMO, POOL_PROMO } from '@utils/links';
import { CtSmLogoSvg, FarmSmLogoSvg, FarmProSmLogoSvg } from '@assets/index';
import {
  AllPromoFirstBgSvg,
  AllPromoSecondBgSvg,
  AllPromoThirdBgSvg,
  AllPromoFourthBgSvg,
  CtpLogoSvg,
} from '@assets/colored';
import { Swiper, SwiperClass, SwiperSlide, useSwiper } from 'swiper/react';
import { Pagination, Keyboard, Controller } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import { useBreakpoints } from '@hooks/useBreakpoints';
import classNames from 'classnames';
import { ArrowRightSmSvg, ArrowLeftSmSvg } from '@assets/colorless';
import { useCallback, useEffect, useState } from 'react';
import Loader from '@shared/Loader';

const PRODUCTS = [
  {
    id: 1,
    bg: AllPromoFirstBgSvg,
    img: {
      src: 'https://cdn.cryptobrowser.site/images/affiliate-redesign/all-promo/all-promo-1.png',
      srcSet: 'https://cdn.cryptobrowser.site/images/affiliate-redesign/all-promo/all-promo-1@2x.png',
    },
    title: 'CT Browser',
    text: 'ct_browser.description',
    Icon: <CtSmLogoSvg width={80} height={80} />,
    url: BROWSER_PROMO,
  },
  {
    id: 2,
    bg: AllPromoSecondBgSvg,
    img: {
      src: 'https://cdn.cryptobrowser.site/images/affiliate-redesign/all-promo/all-promo-2.png',
      srcSet: 'https://cdn.cryptobrowser.site/images/affiliate-redesign/all-promo/all-promo-2@2x.png',
    },
    title: 'CT Pool',
    text: 'ct_pool.description',
    Icon: <CtpLogoSvg width={80} height={80} />,
    url: POOL_PROMO,
  },
  {
    id: 3,
    bg: AllPromoThirdBgSvg,
    img: {
      src: 'https://cdn.cryptobrowser.site/images/affiliate-redesign/all-promo/all-promo-3.png',
      srcSet: 'https://cdn.cryptobrowser.site/images/affiliate-redesign/all-promo/all-promo-3@2x.png',
    },
    title: 'CT Farm',
    text: 'ct_farm.description',
    Icon: <FarmSmLogoSvg width={80} height={80} />,
    url: FARM_PROMO,
  },
  {
    id: 4,
    bg: AllPromoFourthBgSvg,
    img: {
      src: 'https://cdn.cryptobrowser.site/images/affiliate-redesign/all-promo/all-promo-4.png',
      srcSet: 'https://cdn.cryptobrowser.site/images/affiliate-redesign/all-promo/all-promo-4@2x.png',
    },
    title: 'CT Farm PRO',
    text: 'ct_farm_pro.description',
    Icon: <FarmProSmLogoSvg width={80} height={80} />,
    url: FARM_PROMO,
  },
];

const SLIDES_PER_PAGE = 3;

export default function CommonPromoView() {
  const { t } = useTranslation();
  const { isMobile } = useBreakpoints();

  const isLessThanThreeProducts = PRODUCTS.length <= 3;

  const [controlledSwiper, setControlledSwiper] = useState<SwiperClass | null>(null);

  return (
    <Container vertical={20}>
      <Card
        footer={
          !isLessThanThreeProducts &&
          controlledSwiper && <SliderControls controlledSwiper={controlledSwiper} isMobile={isMobile} />
        }
      >
        <div className={styles.body}>
          <Pallet />
          <h1 className={styles.title}>{t('Generate promo materials with your personal link')}</h1>
          <p className={styles.description}>
            {t(
              "Ready-made banners, images of various sizes, and posts—we've taken care of everything. Use our promo materials, attract new users and send invitations to your friends and followers on social networks. Expand your mining network and increase your chances of winning."
            )}
          </p>
          <Swiper
            className={styles.slider}
            slidesPerView={isLessThanThreeProducts && !isMobile ? SLIDES_PER_PAGE : 'auto'}
            spaceBetween={20}
            style={isMobile ? {} : { padding: '0 20px' }}
            modules={[Pagination, Keyboard, Controller]}
            onSwiper={setControlledSwiper}
            keyboard={{
              enabled: true,
              onlyInViewport: false,
            }}
            loop={true}
            pagination={{
              clickable: true,
              el: '.swiper-pagination-custom',
              renderCustom: (_: SwiperClass, current: number, total: number) => {
                return Array.from({ length: total })
                  .map(
                    (_, index) =>
                      `<span class="${classNames('swiper-pagination-bullet', {
                        'swiper-pagination-bullet-active': index + 1 === current,
                      })}"></span>`
                  )
                  .join('');
              },
            }}
          >
            {PRODUCTS.map((product) => (
              <SwiperSlide
                key={product.id}
                className={classNames(styles.product, {
                  [styles.productFixed]: !isLessThanThreeProducts && !isMobile,
                })}
              >
                <Product
                  bg={product.bg}
                  img={product.img}
                  title={product.title}
                  text={product.text}
                  Icon={product.Icon}
                  url={product.url}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </Card>
    </Container>
  );
}

const SliderControls = ({ isMobile, controlledSwiper }: { isMobile?: boolean; controlledSwiper: SwiperClass }) => {
  useEffect(() => {
    controlledSwiper.update();
  }, [controlledSwiper]);

  const handleNextSlide = useCallback(() => controlledSwiper.slideNext(), [controlledSwiper]);
  const handlePrevSlide = useCallback(() => controlledSwiper.slidePrev(), [controlledSwiper]);

  return (
    <div className={styles.sliderControls}>
      <button onClick={handlePrevSlide}>
        <ArrowLeftSmSvg />
      </button>
      {isMobile && <div className={classNames(styles.pagination, 'swiper-pagination-custom')}></div>}
      <button onClick={handleNextSlide}>
        <ArrowRightSmSvg />
      </button>
    </div>
  );
};
