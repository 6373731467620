import React, { CSSProperties, FC, useEffect } from 'react';
import styles from './FarmAboutCode.module.scss';
import { Button, ButtonVariant, Card, Container } from '../index';
import { useTranslation } from 'react-i18next';
import { ReactComponent as FarmAffiliateSvg } from '@assets/colored/farm-affiliate.svg';
import { ReactComponent as StarCardSvg } from '@assets/colorless/start-card.svg';
import Arrow, { ArrowVariant } from '../Arrow';
import { FarmAboutCodeSteps } from '../FarmAboutCodeSteps/FarmAboutCodeSteps';
import { FARM_GENERATE_CODE } from '@utils/links';
import { useDispatch, useSelector } from 'react-redux';
import { rootPromoType } from '@service/reducers/promoReducer';
import getPromos from '@service/actions/getPromos';
import { Picture } from '@shared/Picture/Picture';
import cls from 'classnames';
import { Root } from '@service/reducers';

export type FarmAboutCodeProps = {
  onCodeGeneratePress?: () => void;
  xsVariant?: boolean;
  style?: CSSProperties;
  imageDesktop?: string;
  imageDesktop2x?: string;
  imageMob?: string;
  imageMob2x?: string;
  stepsCss?: string;
  Title?: string;
  Description?: string | JSX.Element;
  isNeedRequest?: boolean;
};

export const FarmAboutCode: FC<FarmAboutCodeProps> = (props) => {
  const {
    onCodeGeneratePress,
    xsVariant,
    style,
    imageDesktop,
    imageDesktop2x,
    imageMob,
    imageMob2x,
    stepsCss,
    Title,
    Description,
    isNeedRequest,
  } = props;
  const { t } = useTranslation();
  const farmPromo = useSelector((store: { promo: rootPromoType }) => store.promo);
  const user = useSelector((store: Root) => store.user);
  const dispatch = useDispatch();
  useEffect(() => {
    if (isNeedRequest) {
      !farmPromo.promo_get && user.id && dispatch(getPromos());
    }
  }, [dispatch, user.id]);
  return (
    <Card className={styles.card}>
      <div className={`${styles.farmAboutCode} ${xsVariant && styles.xsVariant}`} style={style}>
        <Container>
          <FarmAffiliateSvg className={styles.icon} />
          <h1 className={styles.farmAboutCode__title}>{Title}</h1>
          <p className={styles.farmAboutCode__description}>{Description}</p>
          {farmPromo.promos.length < 5 && farmPromo.promos.length > 0 ? (
            <div className={styles.farmAboutCode__btn_wrapper}>
              <Arrow variant={ArrowVariant.Right} />
              {onCodeGeneratePress ? (
                <Button
                  type={'button'}
                  onClick={onCodeGeneratePress}
                  Icon={<StarCardSvg />}
                  variant={ButtonVariant.Primary}
                  className={styles.farmAboutCode__btn}
                >
                  {t('GENERATE CODE')}
                </Button>
              ) : (
                <Button
                  type={'link'}
                  to={FARM_GENERATE_CODE}
                  Icon={<StarCardSvg />}
                  variant={ButtonVariant.Primary}
                  className={styles.farmAboutCode__btn}
                >
                  {t('GENERATE CODE')}
                </Button>
              )}

              <Arrow variant={ArrowVariant.Left} />
            </div>
          ) : null}
          {!xsVariant && (
            <>
              <div className={cls([styles.farmAboutCode__steps, stepsCss])}>
                <FarmAboutCodeSteps />
              </div>

              <div className={styles.farmAboutCode__section_image}>
                <Picture
                  imageDesktop={imageDesktop}
                  imageDesktop2x={imageDesktop2x}
                  imageMob={imageMob}
                  imageMob2x={imageMob2x}
                  className={styles.ui}
                  breakPoint={'600px'}
                ></Picture>
              </div>
            </>
          )}
        </Container>
      </div>
    </Card>
  );
};
