import { Container } from '@shared/Container/Container';
import PoolStatisticGraph from '@pages/PoolStatistic/view/components/PoolStatisticGraph';
import { useTranslation } from 'react-i18next';
import CommonMobControls from '@shared/Graph/CommonMobControls/CommonMobControls';
import { customYformat } from '@utils/graph';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import getBalances from '@service/actions/getBalances';
import getPoolGraphData from '@service/actions/getPoolGraphData';
import { Root } from '@service/reducers';

export default function PoolStatisticView() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const balance = useSelector((store: Root) => store.balance);
  const browserGraph = useSelector((store: Root) => store.browserGraph);
  const pool = useSelector((store: Root) => store.poolStatistic);
  useEffect(() => {
    !balance.get_balance && dispatch(getBalances());
  }, [dispatch, balance.get_balance]);
  useEffect(() => {
    dispatch(getPoolGraphData('link', browserGraph.period_start, browserGraph.period_end, pool.linksDiscreteness));
  }, [browserGraph.period_start, browserGraph.period_end, pool.linksDiscreteness, dispatch]);
  useEffect(() => {
    dispatch(
      getPoolGraphData(
        'registration',
        browserGraph.period_start,
        browserGraph.period_end,
        pool.registrationDiscreteness
      )
    );
  }, [browserGraph.period_start, browserGraph.period_end, pool.registrationDiscreteness, dispatch]);
  useEffect(() => {
    dispatch(
      getPoolGraphData('purchased', browserGraph.period_start, browserGraph.period_end, pool.purchasedDiscreteness)
    );
  }, [browserGraph.period_start, browserGraph.period_end, pool.purchasedDiscreteness, dispatch]);
  useEffect(() => {
    dispatch(
      getPoolGraphData('earnings', browserGraph.period_start, browserGraph.period_end, pool.earningsDiscreteness)
    );
  }, [browserGraph.period_start, browserGraph.period_end, pool.earningsDiscreteness, dispatch]);

  return (
    <Container vertical={20}>
      <CommonMobControls />
      <PoolStatisticGraph title={t('Link Opens')} type={'link'} tooltip={t('Linkclicks.tooltip')} />
      <PoolStatisticGraph title={t('Registrations')} type={'registration'} tooltip={t('Registrations.tooltip')} />
      <PoolStatisticGraph
        title={t('Pool Miners Purchased')}
        type={'purchased'}
        tooltip={t('PoolMinersPurchased.tooltip')}
      />
      <PoolStatisticGraph
        title={t('Earnings')}
        type={'earnings'}
        tooltip={t('Earnings.tooltip')}
        yFormat={customYformat}
      />
    </Container>
  );
}
