import { FC, PropsWithChildren } from 'react';
import { Route, RouteProps, RouteComponentProps } from 'react-router-dom';
import { BaseLayout } from '../BaseLayout/BaseLayout';
import Loader from '@shared/Loader';
import { BrowserSuspend } from '@pages/BrowserSuspend/BrowserSuspend';
import { useAuth } from '@hooks/useAuth';

interface LayoutRouteProps extends RouteProps {
  navigate?: () => void;
  isProtected?: boolean;
  isGetUser?: boolean;
  hideFooter?: boolean;
}

export const LayoutRoute: FC<PropsWithChildren<LayoutRouteProps>> = ({
  children,
  component: Component,
  isProtected = false,
  isGetUser = false,
  hideFooter = false,
  ...rest
}) => {
  const user = useAuth(isGetUser, isProtected);
  const isSuspend =
    !user.tags.includes('withdraw_access') && ['test_suspend', 'form_suspend'].some((tag) => user.tags.includes(tag));
  if (isProtected && !user.hasRequested) {
    return <Loader />;
  }

  if (isProtected && isSuspend) {
    return (
      <Route {...rest}>
        <BrowserSuspend />
      </Route>
    );
  }

  return (
    <Route
      {...rest}
      render={(routeProps: RouteComponentProps<any>) => (
        <BaseLayout component={Component} {...routeProps} hideFooter={hideFooter}>
          {children}
        </BaseLayout>
      )}
    />
  );
};

export default LayoutRoute;
