import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import getFarmActivations from '@service/actions/getFarmActivations';
import getPromos from '@service/actions/getPromos';
import { rootFarmGraph } from '@service/reducers/farmGraphReducer';
import { rootPromoType } from '@service/reducers/promoReducer';
import CardSwitcher from '@shared/CardSwitcher/CardSwitcher';
import { Span } from '@shared/Graph/Tabs';
import styles from './ActivationsCard.module.scss';
import { ReactComponent as PlusSvg } from '@assets/colorless/plus.svg';
import { useHistory } from 'react-router-dom';
import { FARM_GENERATE_CODE } from '@utils/links';
import { rootBrowserGraph } from '@service/reducers/browserStatsReducer';
import { SET_BROWSER_PERIOD } from '@service/constant';
import GraphComponent from '@shared/Graph/GraphComponent/GraphComponent';
import { PromocodeSvg } from '@assets/colorless';

export function ActivationsCard() {
  const history = useHistory();
  const { t } = useTranslation();
  const store = useSelector((store: { promo: rootPromoType; browserGraph: rootBrowserGraph }) => store);
  const period = useSelector((store: { browserGraph: rootBrowserGraph }) => ({
    start: store.browserGraph.period_start,
    end: store.browserGraph.period_end,
  }));
  const dispatch = useDispatch();
  const onPeriodChange = useCallback(
    (period: { period_start: string; period_end: string }) => {
      dispatch({
        type: SET_BROWSER_PERIOD,
        ...period,
      });
    },
    [dispatch]
  );
  const data = useSelector((store: { farmGraph: rootFarmGraph }) => store.farmGraph.activation_data);

  const [selectPeriod, setSelectPeriod] = useState(Span.Day);
  const [active, setActive] = useState(store.promo.promos[0]?.code || 'promo code');

  useEffect(() => {
    !store.promo.promo_get && dispatch(getPromos);
    store.promo.promos[0]?.code &&
      dispatch(
        getFarmActivations(selectPeriod, active, store.browserGraph.period_start, store.browserGraph.period_end)
      );
  }, [
    dispatch,
    store.promo.promo_get,
    selectPeriod,
    active,
    store.promo.promos,
    store.browserGraph.period_start,
    store.browserGraph.period_end,
  ]);

  const handleAddPromo = useCallback(() => {
    history.push(FARM_GENERATE_CODE);
  }, [history]);
  let total = useMemo(() => {
    const res = data.reduce((acc: number, item) => acc + item.value, 0);
    return res;
  }, [data]);
  const List = () => {
    return (
      <>
        {store.promo.promos.length > 1 && (
          <CardSwitcher
            Icon={<PromocodeSvg />}
            activeElement={active}
            containerClass={styles.switcher}
            List={
              <>
                {store.promo.promos.map((_) => (
                  <div
                    className={styles.item}
                    onClick={() => {
                      setActive(_.code);
                    }}
                    key={_.code}
                  >
                    {_.code}
                  </div>
                ))}
                <div className={styles.item__add} onClick={handleAddPromo}>
                  <PlusSvg />
                  {t('Add promo code')}
                </div>
              </>
            }
          />
        )}
      </>
    );
  };
  return (
    <GraphComponent
      SwitchElem={<List />}
      total={total}
      onPeriodChange={onPeriodChange}
      period={period}
      type={'value'}
      onSpanChanged={setSelectPeriod}
      span={selectPeriod}
      title={t('Activations')}
      data={data}
      id="activations"
      tooltip={t('Registrations.tooltip')}
    />
  );
}
