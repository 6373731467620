import { useTranslation } from 'react-i18next';
import styles from './WithdrawalView.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { WithdrawError } from './WithdrawalViewWithMoney';
import ReCAPTCHA from 'react-google-recaptcha';
import { WithdrawalModal } from './WithdrawalModals/WithdrawalModal';
import { Root } from '@service/reducers';
import { BtcDecorImg, BtcDecorRetinaImg, HshDecorImg, HshDecorRetinaImg } from '@assets/img';
import WithdrawTab from './tabs/WithdrawTab';
import { useHandleSubmit } from '@pages/Withdrawal/view/useHandleSubmit';
import { WithdrawAddresses } from '@service/reducers/withdrawalsReducer';
import { ADD_NEW_WITHDRAWAL_ADDRESS, CHANGE_IS_BITCOIN } from '@service/constant';
import { showToast, ToastKind } from '../../../components/Toast/Toast';
import { convertBigValueToLocale } from '@utils/convertBigValueToLocale';
import toast from 'react-hot-toast';
import { BROWSER_WITHDRAWAL, MAIN_URL } from '@utils/links';
import { getWithdrawals } from '@service/actions/getWithdrawals';
import {
  BTC_NCW_CONNECT,
  HSH_NCW_CONNECT,
} from '@pages/WithdrawalNcwWallet/view/WithdrawalNcwWalletView/WithdrawalNcwWalletView';
import cn from 'classnames';
import BannerWithdrawal from '@pages/Withdrawal/view/BannerWithdrawal';

export enum WithdrawTabs {
  Withdraw,
}

export enum WithdrawalModalState {
  Hide,
  Confirm,
  Error,
  Cancel,
  Wallet,
  Change,
  Promo,
  NetworkAlert,
  Loader,
}

export function WithdrawalView() {
  const { t } = useTranslation();
  const store = useSelector((store: Root) => store);
  const balance = useSelector((store: Root) => store.balance.all_balance.wihdraw_balance);
  const isBitcoin = useSelector((store: Root) => store.balance.isBitcoin);
  const balanceHSH = useSelector((store: Root) => store.balance.hsh_balance.current);
  const balancePendingHSH = useSelector((store: Root) => store.balance.hsh_balance.income);
  const balanceAccruedHSH = useSelector((store: Root) => store.balance.hsh_balance.accrued);
  const nextPaymentHSH = useSelector((store: Root) => store.balance.hsh_balance.nextPayment);
  const withdrawals = useSelector((store: Root) => store.withdrawals);
  const user = useSelector((store: Root) => store.user);
  const networkAlert = useSelector((store: Root) => store.networkAlert);
  const history = useHistory();
  const handleBack = useCallback(() => history.push(MAIN_URL), [history]);
  const [amount, setAmount] = useState('');
  const [localHshAccount, setLocalHshAccount] = useState({ address: '' });
  const [address, setAddress] = useState<WithdrawAddresses | null | undefined>(undefined);
  const [error, setError] = useState<WithdrawError>();
  const [modal, setModal] = useState(WithdrawalModalState.Hide);
  const [isLoading, setLoading] = useState(false);
  const [toastId, setToastId] = useState('');
  const [tab, setTab] = useState(WithdrawTabs.Withdraw);
  const googleRef = useRef<ReCAPTCHA>(null);
  const dispatch = useDispatch();

  const hasRequested = useMemo(
    () =>
      store.balance.get_balance &&
      withdrawals.hasRequested &&
      !withdrawals.isLoading &&
      store.user.email_verified &&
      !!store.networkAlert,
    [
      store.balance.get_balance,
      withdrawals.hasRequested,
      !withdrawals.isLoading,
      store.user.email_verified,
      store.networkAlert,
    ]
  );

  const isNCW = useMemo(() => {
    return !hasRequested || !isBitcoin || address?.is_ncw || address === undefined || false;
  }, [isBitcoin, withdrawals.withdrawalsAddresses, address, hasRequested]);

  const minAmount = useMemo(
    () => (isNCW ? +user.ncw_min_withdrawal_amount : +user.min_withdrawal_amount),
    [user.min_withdrawal_amount, isNCW, user.ncw_min_withdrawal_amount]
  );

  const minHSH = useMemo(() => +user.hsh_min_withdrawal, [user.hsh_min_withdrawal]);

  const handleSubmit = useHandleSubmit(
    +minAmount,
    isNCW,
    minHSH,
    amount,
    address?.address || '',
    history,
    setModal,
    setError,
    balance,
    balanceHSH - balancePendingHSH,
    isBitcoin
  );
  const waitTransaction = useMemo(
    () =>
      withdrawals.withdrawals.filter(
        (item) => item.state === 1 || item.state === 2 || item.state === 10 || item.state === 11 || item.state === 12
      ),
    [withdrawals.withdrawals]
  );

  useEffect(() => {
    const modalCookie = !/networkBtcAlert=hide/gi.test(document.cookie);
    if (networkAlert.overload && modalCookie && +balance >= +minAmount) {
      setModal(WithdrawalModalState.NetworkAlert);
    }
  }, [balance, minAmount, networkAlert]);

  useEffect(() => {
    if (hasRequested) {
      const connectInfoHSH = window.localStorage.getItem(HSH_NCW_CONNECT);
      let ncwAddressHSH;

      if (connectInfoHSH) {
        ncwAddressHSH = JSON.parse(connectInfoHSH);

        dispatch({
          type: ADD_NEW_WITHDRAWAL_ADDRESS,
          newWithdrawAddress: { ...ncwAddressHSH, is_local: true },
        });
      }

      const connectInfoBTC = window.localStorage.getItem(BTC_NCW_CONNECT);
      let ncwAddressBTC;

      if (connectInfoBTC) {
        ncwAddressBTC = JSON.parse(connectInfoBTC);

        if (ncwAddressBTC.is_ncw) {
          dispatch({
            type: ADD_NEW_WITHDRAWAL_ADDRESS,
            newWithdrawAddress: { ...ncwAddressBTC, is_local: true },
          });
        }
      }

      if (!isBitcoin && ncwAddressHSH) {
        setAddress(ncwAddressHSH);
        setLocalHshAccount(ncwAddressHSH);
        return;
      }

      if (isBitcoin && ncwAddressBTC) {
        setAddress(ncwAddressBTC as WithdrawAddresses);
        return;
      }

      if (withdrawals.withdrawalsAddresses.length > 0) {
        return setAddress(withdrawals.withdrawalsAddresses[0]);
      }

      setAddress(null);
    }
  }, [isBitcoin, withdrawals.withdrawalsAddresses, hasRequested]);

  const handleModalClose = useCallback(async () => {
    if (modal === WithdrawalModalState.Wallet) {
      setLoading(true);
      try {
        await handleSubmit();
      } finally {
        setLoading(false);
      }
    } else {
      setModal(WithdrawalModalState.Hide);
    }
  }, [handleSubmit, modal]);

  const setIsBitcoin = useCallback((isBitcoin: boolean) => {
    dispatch({ type: CHANGE_IS_BITCOIN, isBitcoin });
    history.push(`${BROWSER_WITHDRAWAL}/${isBitcoin ? 'BTC' : 'HSH'}`);
    dispatch(getWithdrawals(true));
  }, []);

  const resolveNCWAddressChange = useCallback(() => {
    window.localStorage.setItem('resolve_change_ncw', 'true');
    handleModalClose();
  }, [handleModalClose]);

  const onAddressChange = useCallback(
    (val: WithdrawAddresses | string, skip = false) => {
      if (
        !skip &&
        withdrawals.withdrawalsAddresses.length !== 0 &&
        address?.address &&
        withdrawals.withdrawalsAddresses.some((adr) => adr?.address === address?.address) &&
        window.localStorage.getItem('change_modal_show') !== 'true' &&
        window.localStorage.getItem('resolve_change_ncw') !== 'true'
      ) {
        setModal(WithdrawalModalState.Change);
      } else {
        setAddress(typeof val === 'string' ? { address: val } : val);
      }
    },
    [withdrawals.withdrawalsAddresses, address]
  );

  const openChangeModal = useCallback(() => {
    if (
      withdrawals.withdrawalsAddresses.length !== 0 &&
      address?.address &&
      withdrawals.withdrawalsAddresses.some((adr) => adr?.address === address?.address) &&
      window.localStorage.getItem('change_modal_show') !== 'true' &&
      window.localStorage.getItem('resolve_change_ncw') !== 'true'
    ) {
      setModal(WithdrawalModalState.Change);
    }
  }, [withdrawals.withdrawalsAddresses, address]);

  const disabled = useMemo(() => {
    return (
      address === null ||
      amount === '' ||
      (waitTransaction.length >= 10 && isBitcoin) ||
      (+amount < +minAmount && isBitcoin)
    );
  }, [minAmount, address, amount, waitTransaction.length]);

  const enoughMoney = useMemo(
    () => (isBitcoin && +balance !== 0) || (!isBitcoin && +balanceHSH !== 0),
    [balance, balanceHSH, minAmount, isBitcoin]
  );

  useEffect(() => {
    const localDate = localStorage.getItem('lastPendingToastShow') || '{}';
    const lastShowToast = JSON.parse(localDate)?.date || 0;
    const ONE_DAY = 86_400_000;

    if (!isBitcoin) {
      if (balancePendingHSH && Date.now() - lastShowToast >= ONE_DAY) {
        showToast(t('HSH.Pending_toast', { HSH: convertBigValueToLocale(balancePendingHSH) }), ToastKind.Info);
        localStorage.setItem('lastPendingToastShow', JSON.stringify({ date: Date.now() }));
      }
    } else {
      toast.remove();
    }

    return () => toast.remove();
  }, [isBitcoin, balancePendingHSH]);

  useEffect(() => {
    if (isBitcoin) {
      if (+balanceHSH > 0) {
        const isVisitHSH = localStorage.getItem('isVisitHSH') || false;

        if (!isVisitHSH) {
          setToastId(showToast(t('HSH.Interf.Toast.Withdr_here'), ToastKind.Info));
        }
      }
    } else {
      localStorage.setItem('isVisitHSH', 'true');
      toast.remove(toastId);
    }

    return () => toast.remove();
  }, [isBitcoin, balanceHSH]);

  return (
    <>
      <div className={styles.container}>
        <img
          src={BtcDecorImg}
          srcSet={BtcDecorRetinaImg + ' 2x'}
          alt="btc"
          className={cn(styles.decoration, isBitcoin && styles[`decoration--show`])}
        />
        <img
          src={HshDecorImg}
          srcSet={HshDecorRetinaImg + ' 2x'}
          alt="hsh"
          className={cn(styles.decoration, !isBitcoin && styles[`decoration--show`])}
        />
        <div className={styles.card}>
          {tab === WithdrawTabs.Withdraw && (
            <WithdrawTab
              balance={balance}
              balanceHSH={balanceHSH}
              balancePendingHSH={balancePendingHSH}
              balanceAccruedHSH={balanceAccruedHSH}
              nextPaymentHSH={nextPaymentHSH}
              lastAddressHSH={withdrawals.withdrawalsAddresses[0]?.address || localHshAccount?.address}
              isBitcoin={isBitcoin}
              setIsBitcoin={setIsBitcoin}
              waitTransactionLength={waitTransaction.length}
              address={address}
              setAmount={setAmount}
              handleBack={handleBack}
              googleRef={googleRef}
              error={error}
              setError={setError}
              minAmount={minAmount}
              minHSH={minHSH}
              isNcw={isNCW}
              disabled={disabled || isLoading}
              handleSubmit={handleSubmit}
              setLoading={setLoading}
              openChangeModal={openChangeModal}
              onAddressChange={onAddressChange}
              amount={amount}
              modal={modal}
              isLoading={isLoading}
              hasRequested={hasRequested}
              isSuspend={user.tags.includes('withdraw_suspend') && !user.tags.includes('withdraw_access')}
              enoughMoney={enoughMoney}
            />
          )}
        </div>
      </div>

      {!enoughMoney && (
        <div className={styles.withdrawBannerMobile}>
          <BannerWithdrawal />
        </div>
      )}

      {modal !== WithdrawalModalState.Hide && (
        <WithdrawalModal
          variant={modal}
          handleModalClose={handleModalClose}
          onResolveChange={resolveNCWAddressChange}
          isBitcoin={isBitcoin}
        />
      )}
    </>
  );
}
