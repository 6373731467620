import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useMemo } from 'react';
import getBalances from '@service/actions/getBalances';
import { MAIN_LINK_URL, POOL_LINKS, POOL_STATISTIC } from '@utils/links';
import { SET_POOL_PERIOD } from '@service/constant';
import getPoolStatsLinks from '@service/actions/getPoolStatsLinks';
import LinksView from '@shared/LinksView/LinksView';
import useFilteredPoolLinks from '@hooks/useFilteredPoolLinks';
import { Root } from '@service/reducers';
import { useTranslation } from 'react-i18next';
import { PRODUCT_KIND } from '@utils/checkPathname';
import { useGeneratePostModal } from '@hooks/useGeneratePostModal';

export function PoolLinks() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const store = useSelector((store: Root) => ({
    balance: store.balance,
    poolStatistic: store.poolStatistic,
  }));
  const poolLinks = useSelector((store: Root) => store.poolStatistic);
  const balance = useSelector((store: Root) => store.balance);
  const { period_start, period_end } = useSelector((store: Root) => store.browserGraph);
  const period = useSelector((store: Root) => ({
    start: period_start,
    end: period_end,
  }));

  const onPeriodChange = useCallback(
    (period: { period_start: string; period_end: string }) => {
      dispatch({
        type: SET_POOL_PERIOD,
        ...period,
      });
      dispatch(getPoolStatsLinks(period.period_start, period.period_end));
    },
    [dispatch]
  );

  const handleRefresh = useCallback(() => {
    dispatch(getBalances());
    dispatch(getPoolStatsLinks(period_start, period_end));
  }, [dispatch, period_start, period_end]);
  const count = useMemo(() => store.balance.links.filter((i) => i.type !== 'farm').length, [store.balance.links]);
  const totalInfo = useFilteredPoolLinks();

  const { handleGeneratePostClick } = useGeneratePostModal(PRODUCT_KIND.Pool, { timeoutDelay: 2000 });
  const isLoading = !poolLinks.stats_links_get && !balance.get_balance;

  return (
    <LinksView
      count={count}
      handleRefresh={handleRefresh}
      projectType={'pool'}
      link={POOL_LINKS}
      landLink={'https://pool.cryptobrowser.site/landing/?aid='}
      period={period}
      totalInfo={totalInfo}
      onPeriodChange={onPeriodChange}
      linksStatisticInfo={{
        path: MAIN_LINK_URL,
        title: t('View all'),
      }}
      onLinkCreate={handleGeneratePostClick}
      isLoading={isLoading}
    />
  );
}
