import styles from './Subheader.module.scss';
import { PropsWithChildren, ReactNode } from 'react';
import { MAIN_URL } from '@utils/links';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { RectSvg } from '@assets/colorless';
import { AllSvg } from '@assets/colored';
import { useSelector } from 'react-redux';
import { Root } from '@service/reducers';

export type SubheaderProps = PropsWithChildren<{
  Icon?: ReactNode;
}>;

export default function Subheader(props: SubheaderProps) {
  const { children, Icon } = props;
  const { t } = useTranslation();
  const { isTable, isMobile } = useSelector((state: Root) => state.system);

  if (isMobile) {
    return <nav className={styles.subheader__menu}>{children}</nav>;
  }

  return (
    <div className={styles.subheader}>
      {/*<div className={styles.subheader__toggle}>*/}
      {/*  <div className={styles.subheader__toggle_scroll}>*/}
      {/*    <TabsButtons />*/}
      {/*  </div>*/}
      {/*</div>*/}

      {children ? (
        <nav className={styles.subheader__menu}>
          <div className={styles.subheader__menu_scroll}>
            {Icon && <div className={styles.icon}>{Icon}</div>}
            <div className={styles.subheader__menu_children}>{children}</div>
          </div>
        </nav>
      ) : (
        <div className={styles.hide_menu}>
          <div className={styles.icon}>
            <AllSvg />
          </div>
          <NavLink
            exact
            to={{ pathname: MAIN_URL }}
            activeClassName={styles.active}
            isActive={(match, location) => !!location.pathname.match(/app$|app\/$/)}
          >
            <RectSvg />
            <b>{t('Overview')}</b>
          </NavLink>
        </div>
      )}
    </div>
  );
}
