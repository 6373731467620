import { FC } from 'react';
import Subheader from '../../components/Subheader';
import styles from '../../components/Subheader/Subheader.module.scss';
import { CtSmLogoSvg } from '@assets/index';
import { config } from '@pages/headersComponents/config';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useGoBack } from '../../hooks/useGoBack';
import { BackButton } from '@shared/BackButton/BackButton';

export type TBrowserSubheaderProps = {
  isShowBackButton?: boolean;
};

export const BrowserSubheader: FC<TBrowserSubheaderProps> = ({ isShowBackButton }) => {
  const { t } = useTranslation();

  return (
    <Subheader Icon={<CtSmLogoSvg />}>
      {isShowBackButton && <BackButton />}

      {config.browser.map((b) => (
        <NavLink to={{ pathname: b.url }} key={b.url} activeClassName={styles.active}>
          {b.Icon}
          {t(b.text)}
        </NavLink>
      ))}
    </Subheader>
  );
};
