import React, { ButtonHTMLAttributes, PropsWithChildren, ReactNode, forwardRef, useMemo } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import cls from 'classnames';
import styles from './Button.module.scss';

export enum ButtonVariant {
  Default,
  Primary,
  Danger,
  Text,
  Inverted,
  Transparent,
}

export type ButtonPropsAsButton = ButtonHTMLAttributes<HTMLButtonElement> & {
  type: 'button';
  name?: string;
};

export type ButtonPropsAsLink = LinkProps & {
  type: 'link';
  target?: string;
};

export type ButtonProps = PropsWithChildren<ButtonPropsAsButton | ButtonPropsAsLink> & {
  Icon?: ReactNode;
  iconPosition?: 'right' | 'left';
  variant?: ButtonVariant;
  size?: 'default' | 'medium' | 'small';
  isActive?: boolean;
  isTabActive?: boolean;
  external?: boolean;
  noSvgColorized?: boolean;
  disabled?: boolean;
  isDisabledDark?: boolean;
  isLoading?: boolean;
  onlyIcon?: boolean;
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(({ children, ...rest }, ref) => {
  const {
    variant = ButtonVariant.Default,
    type,
    noSvgColorized,
    className = '',
    Icon,
    size = 'default',
    isActive,
    isTabActive,
    external,
    disabled,
    isDisabledDark,
    iconPosition = 'left',
    isLoading,
    onlyIcon,
  } = rest;

  const classNames = useMemo(
    () =>
      cls({
        [styles.btn]: true,
        [styles.no_svg_colorized]: noSvgColorized,
        [styles.btn__primary]: variant === ButtonVariant.Primary,
        [styles.btn__danger]: variant === ButtonVariant.Danger,
        [styles.btn__inverted]: variant === ButtonVariant.Inverted,
        [styles.btn__text]: variant === ButtonVariant.Text,
        [styles.btn__transparent]: variant === ButtonVariant.Transparent,
        [styles.btn__disabled_dark]: isDisabledDark,
        [styles.btn__small]: size === 'small',
        [styles.btn__medium]: size === 'medium',
        [styles.btn__active]: isActive,
        [styles.btn__tab_active]: isTabActive,
        [styles.btn__is_loading]: isLoading,
        [styles.btn__only_icon]: onlyIcon,
      }),
    [variant, isActive, noSvgColorized, isLoading, isTabActive, size]
  );

  if (type === 'link') {
    return external ? (
      <a href={rest.to as string} className={`${classNames} ${className}`} target={rest.target} data-anim="fadeInUp">
        {Icon && iconPosition === 'left' && Icon}
        {isLoading && (
          <div className={styles.is_loading__spinner}>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
          </div>
        )}
        {children && <span>{children}</span>}
        {Icon && iconPosition === 'right' && Icon}
      </a>
    ) : (
      <Link to={rest.to} className={`${classNames} ${className}`} data-anim="fadeInUp">
        {Icon && iconPosition === 'left' && Icon}
        {children && <span>{children}</span>}
        {Icon && iconPosition === 'right' && Icon}
      </Link>
    );
  }

  return (
    <button
      ref={ref}
      className={`${classNames} ${className}`}
      onClick={rest.onClick}
      disabled={disabled}
      data-anim="fadeInUp"
      name={rest.name}
    >
      {Icon && iconPosition === 'left' && Icon}
      {isLoading && (
        <div className={styles.is_loading__spinner}>
          <i></i>
          <i></i>
          <i></i>
          <i></i>
        </div>
      )}
      {children && <span>{children}</span>}
      {Icon && iconPosition === 'right' && Icon}
    </button>
  );
});
