import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { rootUser } from '@service/reducers/userReducer';
import { Button, ButtonVariant } from '@shared/index';
import { LoadSpinner } from '@shared/Loader';
import { SettingsItem } from './SettingsItem';
import { SettingsLogin } from './SettingsLogIn';
import styles from './SettingsView.module.scss';

import { ReactComponent as UserSvg } from '@assets/colorless/user.svg';
import { ReactComponent as LogoutSvg } from '@assets/colorless/logout-new.svg';

import btcLeft from '@assets/btc-left-new.png';
import btcLeftRetina from '@assets/btc-left-new@2x.png';
import btcRight from '@assets/btc-right-new.png';
import btcRightRetina from '@assets/btc-right-new@2x.png';

import btcMob from '@assets/btc-mob.png';
import btcMobRetina from '@assets/btc-mob@2x.png';

import { ModalWallet } from '../../Withdrawal/view/PromoModals/ModalWallet';
import { Picture } from '@shared/Picture/Picture';
import cls from 'classnames';
import { CrossCircleSvg } from '@assets/colorless';
import NCWCheck from './NCWCheck/NCWCheck';

export function SettingsView() {
  const { t } = useTranslation();
  const user = useSelector((store: { user: rootUser }) => store.user);
  const [showDropdown, setDropdownShown] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const isAnyNCW = user.social.some((item) => item.is_ncw);

  const ncwShow = user.social.some((item) => {
    if (item.provider) {
      return (item.provider === 'google-oauth2' || item.provider === 'apple-id') === true;
    }
    return false;
  });

  return (
    <>
      <div className={styles.container}>
        <div className={styles.card}>
          <div className={styles.decoration}>
            <div className={cls([styles.animatedWrap, styles['animatedWrap--left']])}>
              <Picture
                imageDesktop={btcLeft}
                imageDesktop2x={btcLeftRetina}
                imageMob={btcMob}
                imageMob2x={btcMobRetina}
                breakPoint={'600px'}
              ></Picture>
            </div>

            <div className={cls([styles.animatedWrap, styles['animatedWrap--right']])}>
              <Picture imageDesktop={btcRight} imageDesktop2x={btcRightRetina} breakPoint={'600px'}></Picture>
            </div>
          </div>
          <div className={styles.cardWrapper}>
            {!showDropdown && <div className={styles.card__header}>{t('Account')}</div>}
            {showDropdown && <div className={styles.card__header}>{t('Change account')}</div>}
            <div className={styles.card__body}>
              {user.social_request && (
                <div className={styles.info}>
                  <LoadSpinner />
                </div>
              )}
              {user.social_error && (
                <div className={styles.info}>
                  <span>{t('Something Went Wrong')}...</span>
                </div>
              )}
              <div className={styles.card__items}>
                {!user.social_request &&
                  user.social.map((item) => (
                    <SettingsItem
                      key={item.id}
                      isAnyNCW={isAnyNCW}
                      {...item}
                      canDelete={false && user.social.length === 1}
                    />
                  ))}
                {!user.social_request && ncwShow && (
                  <NCWCheck isCheck={isAnyNCW} showPromo={() => setShowModal(true)} />
                )}
              </div>

              {showDropdown && <SettingsLogin />}
            </div>
            <div className={styles.card__footer}>
              {!showDropdown ? (
                <>
                  <Button
                    type="button"
                    size="medium"
                    iconPosition="left"
                    className={styles.backBtn}
                    Icon={<LogoutSvg />}
                    onClick={() => {
                      localStorage.removeItem('tokens');
                      window.location.href = `/logout/?next=/login/`;
                    }}
                  >
                    {t('Logout')}
                  </Button>
                  <Button
                    type="button"
                    size="medium"
                    iconPosition="left"
                    Icon={<UserSvg color="#fff" />}
                    variant={ButtonVariant.Primary}
                    onClick={() => setDropdownShown(true)}
                  >
                    {t('Add account')}
                  </Button>
                </>
              ) : (
                <Button
                  type="button"
                  size="medium"
                  Icon={<CrossCircleSvg />}
                  className={styles.backBtn}
                  onClick={() => setDropdownShown(false)}
                >
                  {t('CANCEL')}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <ModalWallet
          onClose={() => setShowModal(false)}
          link="https://ncwallet.page.link/?link=https%3A%2F%2Fapp.ncwallet.net&apn=com.ncwallet&amv=0&ibi=com.ncwallet&imv=0&isi=1615381976&referrer=utm_source%3Dsite%26utm_medium%3Daffiliate_user"
        />
      )}
    </>
  );
}
