import React, { HTMLAttributes, PropsWithChildren } from 'react';
import styles from './Card.module.scss';
import cls from 'classnames';

export type CardProps = PropsWithChildren<
  HTMLAttributes<HTMLDivElement> & {
    title?: string;
    TitleElement?: React.ReactNode;
    Switch?: React.ReactNode | string;
    headerClass?: string;
    ref?: React.RefObject<HTMLDivElement>;
    footer?: React.ReactNode;
    footerClass?: string;
    withCustomFooter?: boolean;
  }
>;

export function Card({
  title,
  Switch,
  TitleElement,
  className,
  children,
  id,
  headerClass,
  footer,
  footerClass,
  withCustomFooter,
  ...rest
}: CardProps) {
  return (
    <>
      <div
        className={cls([styles.card, className], { [styles.with_footer]: !!footer || withCustomFooter })}
        id={id}
        {...rest}
      >
        {(title || TitleElement) && (
          <div className={cls(styles.header, headerClass)}>
            {TitleElement || <span>{title}</span>}
            {Switch && Switch}
          </div>
        )}
        <div className={styles.card_content}>{children}</div>
        {footer && <div className={cls(styles.footer, footerClass, 'opacityFooterButtons')}>{footer}</div>}
      </div>
    </>
  );
}
