import styles from './Header.module.scss';
import CommonNavMenu from './CommonNavMenu';
import { AllSvg } from '@assets/colored';
import { ChevronLeftSvg, ChevronRightSvg } from '@assets/colorless';
import { useSelector } from 'react-redux';
import { Root } from '@service/reducers';
import { useCallback, useEffect, useState } from 'react';
import { mobConfig, MobHeaderState } from './mobConfig';
import { useHistory, useLocation } from 'react-router-dom';
import SimpleMobileHeader from './SimpleMobileHeader';
import { useTranslation } from 'react-i18next';

export default function MobileHeader() {
  const { isMobile } = useSelector((state: Root) => state.system);
  const [state, setState] = useState<MobHeaderState | null>(mobConfig[0]);
  const pageTitle = useSelector((store: Root) => store.system.pageTitle);
  const { pathname } = useLocation();
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    const find = mobConfig.find((c, idx) => {
      const path = pathname.replace(/\/$/gi, '');
      return c.urls.includes(path);
    });
    setState(find || null);
  }, [pathname, isMobile]);

  const handleChange = useCallback(
    (index: number) => {
      const newState = mobConfig[index];
      const path = pathname.split('/').filter((k, i) => (k === '' && i > 0 ? false : true));
      const last = path.length > 2 ? path[path.length - 1] : 'dashboard';
      const newRoute = `/app${index !== 0 ? newState.baseUrl : ''}/${last}`;
      history.push(newRoute.includes('app/dashboard') ? '/app' : newRoute);
      return newState;
    },
    [history, pathname]
  );

  if (state === null) {
    return <SimpleMobileHeader title={pageTitle} />;
  }

  return (
    <div className={styles.mob_container}>
      <AllSvg className={styles.mob_logo} />
      <div className={styles.switcher__container}>
        <div className={styles.switcher}>
          <ChevronLeftSvg onClick={() => setState((prev) => prev && handleChange(prev.prev))} />
          <div>
            {state.Icon}
            <span>{t(state.text)}</span>
          </div>
          <ChevronRightSvg onClick={() => setState((prev) => prev && handleChange(prev.next))} />
        </div>
      </div>
      <CommonNavMenu />
    </div>
  );
}
