import React, { useEffect } from 'react';
import { ReactComponent as Change } from '@assets/colored/change-wallet-icon.svg';
import { useTranslation } from 'react-i18next';
import { Button, ButtonVariant } from '@shared/index';
import { CheckSvg, CircleCancelSvg } from '@assets/colorless';
import { Modal } from '../../../../shared/Modal';

export type ModalChangeProps = {
  onClose: () => void;
  onResolveChange: () => void;
};

export default function ModalChange({ onClose, onResolveChange }: ModalChangeProps) {
  useEffect(() => {
    window.localStorage.removeItem('changeShow');
  }, []);
  const { t } = useTranslation();

  return (
    <Modal.Content>
      <Modal.Body>
        <Modal.Icon icon={<Change />} />
        <Modal.TitleDescription title={t('changewallet.title')} description={t('changewallet.txt')} />
      </Modal.Body>
      <Modal.Footer>
        <Button type="button" onClick={onClose} size="medium" Icon={<CircleCancelSvg />}>
          {t('changewallet.button.cancel')}
        </Button>
        <Button
          type="button"
          variant={ButtonVariant.Primary}
          onClick={onResolveChange}
          size="medium"
          Icon={<CheckSvg />}
        >
          {t('changewallet.button.confirm')}
        </Button>
      </Modal.Footer>
    </Modal.Content>
  );
}
