import { usePromoShowOthers } from '@hooks/usePromoShowOthers';
import FooterPromoSection from '@shared/FooterPromoSection/FooterPromoSection';
import { CardImage, PromoSection } from '@shared/index';
import { Tabs as ImagesTabs } from '@shared/Tabs/Tabs';
import { PRODUCT_KIND } from '@utils/checkPathname';
import { createLinkToPromo, PromoImageType } from '@utils/createLinkToPromo';
import { range } from '@utils/range';
import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './PromoImagesSection.module.scss';
import React from 'react';
import { RectangleSvg, SquareSvg, StoriesSvg } from '@assets/colorless';

enum Tabs {
  Reactangle = 'Rectangle',
  Square = 'Square',
  Storie = 'Stories',
}

const icons = {
  Rectangle: RectangleSvg,
  Square: SquareSvg,
  Stories: StoriesSvg,
};

export const MAX_PROMO_IMAGES = 9;
const STEP = 24;
const ARR = range(MAX_PROMO_IMAGES);

type PromoImagesSectionProps = {
  productKind: PRODUCT_KIND.Browser | PRODUCT_KIND.Farm | PRODUCT_KIND.FarmPro | PRODUCT_KIND.Pool;
};

export const PromoImagesSection = ({ productKind }: PromoImagesSectionProps) => {
  const { t } = useTranslation();
  const [tab, setTab] = useState(Tabs.Reactangle);
  const { visible, onOther, showAll } = usePromoShowOthers(STEP, MAX_PROMO_IMAGES);
  return (
    <PromoSection
      title={t('Images for promo posts')}
      text={t('You can post these images on your Facebook, Twitter, e.g.')}
      id="social"
      Switch={
        <ImagesTabs
          active={tab}
          setState={setTab}
          items={[Tabs.Reactangle, Tabs.Square, Tabs.Storie].map((_) => ({
            text: t(_),
            kind: _,
            icon: icons[_] ? React.createElement(icons[_]) : null,
          }))}
        />
      }
      Footer={!(MAX_PROMO_IMAGES < STEP) && <FooterPromoSection onOtherClick={onOther} onAllClick={showAll} />}
    >
      <div className={classNames(styles.result, styles[tab])}>
        {ARR.slice(visible.from, visible.to).map((_) => (
          <CardImage key={_ + tab} src={createLinkToPromo(productKind, tabsToImageMap[tab], _)} active={tab + '_br'} />
        ))}
      </div>
    </PromoSection>
  );
};

const tabsToImageMap: Record<Tabs, PromoImageType> = {
  [Tabs.Reactangle]: 'landscape',
  [Tabs.Square]: 'square',
  [Tabs.Storie]: 'portrait',
};
