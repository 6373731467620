import { ComponentType, FC, ReactNode } from 'react';
import Header from '../../Header';
import Footer from '../../Footer';
import { ScrollToTop } from '../../ScrollToTop';
import { RouteProps, RouteComponentProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Root } from '@service/reducers';
import { Toaster } from 'react-hot-toast';
import styles from '../../App/App.module.scss';
import { useBreakpoints } from '@hooks/useBreakpoints';

interface BaseLayoutProps extends RouteProps {
  children?: ReactNode | undefined;
  component?: ComponentType<RouteComponentProps<any>>;
  hideFooter?: boolean;
}

export const BaseLayout: FC<BaseLayoutProps & RouteComponentProps<any>> = ({
  children,
  component: Component,
  hideFooter = false,
  ...rest
}) => {
  const backgroundColor = useSelector((store: Root) => store.system.backgroundColor);
  const { isMobile } = useBreakpoints();

  return (
    <main className={styles[`main__bg-${backgroundColor}`]}>
      <Header />
      <Toaster containerStyle={{ top: isMobile ? 15 : 150 }} />
      {Component ? <Component {...rest} /> : children}
      {!hideFooter && <Footer />}
      <ScrollToTop />
    </main>
  );
};
