import { Trans } from 'react-i18next';
import { ErrorSvg } from '@assets/colored';
import BannerWithdrawal from './BannerWithdrawal';

import styles from './WithdrawalView.module.scss';
import checkE from '@utils/checkE';

export type WithdrawalViewNotMoneyProps = {
  minAmount: number;
  minHSH: number;
  isBitcoin: boolean;
};

export function WithdrawalViewNotMoney({ minAmount, minHSH, isBitcoin = true }: WithdrawalViewNotMoneyProps) {
  return (
    <>
      <ErrorSvg className={styles.error__icon} />
      <div className={styles.error__title}>
        {isBitcoin ? (
          <Trans i18nKey={'You do not have enough'} values={{ coin: 'BTC' }} />
        ) : (
          <Trans i18nKey={'HSH.WIthdr_scr.No_HSH.Title'} />
        )}
      </div>
      <div className={styles.error__text}>
        <Trans
          i18nKey={isBitcoin ? 'Withdraw.Description2' : 'HSH.WIthdr_scr.No_HSH.Description'}
          values={{ amount: isBitcoin ? checkE(minAmount) : minHSH, coin: isBitcoin ? 'BTC' : 'HSH' }}
        />
      </div>

      <div className={styles.withdrawBannerDesktop}>
        <BannerWithdrawal />
      </div>
    </>
  );
}
