import styles from './CalcModal.module.scss';
import { Button, ButtonVariant } from '@shared/index';

import { ReactComponent as TickSvg } from '@assets/colorless/tick.svg';
import { ReactComponent as AffiliateModalSvg } from '@assets/colored/affiliate-modal.svg';
import { Trans, useTranslation } from 'react-i18next';
import { FC } from 'react';
import { Modal } from '../../../../../../shared/Modal';

type CalcModalProps = {
  onClose: () => void;
};

export const CalcModal: FC<CalcModalProps> = (props) => {
  const { onClose } = props;
  const { t } = useTranslation();
  return (
    <Modal.Content>
      <Modal.Body>
        <Modal.Icon icon={<AffiliateModalSvg />} />
        <Modal.TitleDescription
          title={t('Affiliate Program Income')}
          description={t(
            'Once you join Affiliate Program and invite referrals, you get a bonus equal to a percentage of their mining profits. And also, an additional percentage is given for each user invited by your referrals — up to the 10 level of your mining network.'
          )}
        />
        <Modal.InnerContent>
          <div className={styles.calcModal__card__body}>
            <div className={styles.calcModal__frame}>
              <h5>{t('Bonuses from referral system:')}</h5>
              <ul>
                <li>
                  <Trans
                    i18nKey={'%(level)s level — %(percent)s%% of referrals mining'}
                    values={{
                      level: 1,
                      percent: 15,
                    }}
                    components={{ b: <b /> }}
                  />
                </li>
                <li>
                  <Trans
                    i18nKey={'%(level)s level — %(percent)s%% of referrals mining'}
                    values={{
                      level: 2,
                      percent: 10,
                    }}
                    components={{ b: <b /> }}
                  />
                </li>
                <li>...</li>
                <li>
                  <Trans
                    i18nKey={'%(level)s level — %(percent)s%% of referrals mining'}
                    values={{
                      level: 10,
                      percent: 0.0625,
                    }}
                    components={{ b: <b /> }}
                  />
                </li>
              </ul>
            </div>

            <p className={styles.calcModal__card_note}>
              <Trans
                i18nKey={
                  'Note: we use the average monthly income of the CryptoTab users equal to $4 in BTC when calculating estimated income. You get bonuses from Affiliate Program as long as your referrals actively use CryptoTab Browser.'
                }
                components={{ b: <b /> }}
              />
            </p>
          </div>
        </Modal.InnerContent>
      </Modal.Body>
      <Modal.Footer>
        <Button type={'button'} variant={ButtonVariant.Primary} onClick={onClose} Icon={<TickSvg />}>
          {t('Ok')}
        </Button>
      </Modal.Footer>
    </Modal.Content>
  );
};
