import {
  POOL_LINKS_REQUEST,
  POOL_OPENS_SUCCESS,
  POOL_INSTALLS_SUCCESS,
  MAIN_EARNING_SUCCESS,
  BROWSER_LINKS_REQUEST,
  BROWSER_LINKS_SUCCESS,
  POOL_LINKS_ERROR,
  BROWSER_CHANGE_TABLE_OPENS,
  BROWSER_CHANGE_TABLE_INSTALLS,
  BROWSER_CHANGE_TABLE_MINERS,
  POOL_MINERS_SUCCESS,
  MAIN_CHANGE_EARNING_TOTAL,
} from '@service/constant';
import checkE from '@utils/checkE';
import { AppDispatch } from '../..';
import { AffiliateGroup, Discreteness, Product } from '../../backend/JsonRpcApi';
import { rpc } from '../../backend/Rpc';
import { Span } from '@shared/Graph/Tabs';

const updateTimestamp = <T extends { timestamp: number }>(arr: T[]): T[] =>
  arr.map((item) => ({ ...item, timestamp: item.timestamp * 1000 }));

async function fetchStatistics(startDate: string, endDate: string, group: AffiliateGroup, discreteness?: Discreteness) {
  return await rpc.transmit('statistics.referrals.payments', {
    start_date: startDate,
    end_date: endDate,
    group,
    discreteness,
  });
}

export default function getMainInfo(
  period_start: string,
  period_end: string,
  earningDiscreteness?: Discreteness,
  isChangeEarningsDiscreetness?: boolean
) {
  return async function (dispatch: AppDispatch) {
    try {
      dispatch({ type: POOL_LINKS_REQUEST });
      if (!isChangeEarningsDiscreetness) {
        const [opensBrowser, opensPool, installs] = await Promise.all([
          await rpc.transmit('statistics.v2.clicks', {
            start_date: period_start,
            end_date: period_end,
            discreteness: Span.Day,
            product_ids: [Product.CT],
          }),
          rpc.transmit('statistics.v2.clicks', {
            start_date: period_start,
            end_date: period_end,
            discreteness: Span.Day,
            product_ids: [Product.Pool],
          }),
          rpc.transmit('statistics.referrals', {
            start_date: period_start,
            end_date: period_end,
            discreteness: Span.Day,
          }),
        ]);

        const updateOpens = opensPool
          .map((item) => ({ timestamp: item.timestamp, account_id: +item.advert_id, value: item.value }))
          .sort((a, b) => a.timestamp - b.timestamp);

        dispatch({ type: POOL_OPENS_SUCCESS, opens: updateOpens });
        const browserOpens = opensBrowser
          .map((item) => ({ timestamp: item.timestamp, account_id: +item.advert_id, value: item.value }))
          .sort((a, b) => a.timestamp - b.timestamp);
        dispatch({ type: BROWSER_CHANGE_TABLE_OPENS, payload: browserOpens });

        const updateInstalls = updateTimestamp(installs.items).sort((a, b) => a.timestamp - b.timestamp);
        dispatch({
          type: BROWSER_CHANGE_TABLE_INSTALLS,
          payload: updateInstalls,
        });
        dispatch({
          type: POOL_INSTALLS_SUCCESS,
          installs: updateInstalls,
        });
      }
      const [poolMiners, browserMiners, farmMiners] = await Promise.all([
        fetchStatistics(period_start, period_end, AffiliateGroup.Pool, Span.Day),
        fetchStatistics(period_start, period_end, AffiliateGroup.Browser, Span.Day),
        fetchStatistics(period_start, period_end, AffiliateGroup.Farm, earningDiscreteness),
      ]);

      const earningsData = [...browserMiners.items, ...farmMiners.items, ...poolMiners.items].sort((a, b) => {
        if (a.timestamp !== undefined && b.timestamp !== undefined) {
          return a.timestamp - b.timestamp;
        } else {
          return 0;
        }
      });
      const earningDataModified = earningsData.map((item) => ({
        timestamp: item.timestamp ? item.timestamp * 1000 : undefined,
        value: +checkE(item.amount),
      }));

      dispatch({ type: MAIN_EARNING_SUCCESS, earnings: earningDataModified });

      dispatch({ type: BROWSER_LINKS_REQUEST });

      dispatch({
        type: BROWSER_CHANGE_TABLE_MINERS,
        payload: browserMiners.items
          .map((item) => ({
            ...item,
            timestamp: item.timestamp! * 1000,
            value: item.users,
          }))
          .sort((a, b) => a.timestamp - b.timestamp),
      });
      dispatch({
        type: POOL_MINERS_SUCCESS,
        miners: poolMiners.items
          .map((item) => ({
            ...item,
            timestamp: item.timestamp! * 1000,
            value: item.users,
          }))
          .sort((a, b) => a.timestamp - b.timestamp),
      });
      const earningTotal = checkE(earningsData.reduce((acc, item) => acc + item.amount, 0));
      dispatch({ type: MAIN_CHANGE_EARNING_TOTAL, earningTotal: earningTotal });
      dispatch({ type: BROWSER_LINKS_SUCCESS });
    } catch (e) {
      dispatch({ type: POOL_LINKS_ERROR });
      console.log(e);
    }
  };
}
