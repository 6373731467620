import { useCallback, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import styles from './CopyText.module.scss';
import { ReactComponent as CopySVG, ReactComponent as Copy } from '@assets/colorless/copy.svg';
import { Button } from '@shared/Button/Button';
import { ReactComponent as CheckSVG } from '@assets/colorless/check.svg';

export type CopyTextProps = {
  text: string;
  isBtn?: boolean;
  textForCopy?: string;
};

export function CopyText({ text, isBtn, textForCopy }: CopyTextProps) {
  const { t } = useTranslation();
  const [copy, setText] = useState(true);
  const onCopy = useCallback(() => {
    setText(false);
    setTimeout(() => setText(true), 2000);
  }, []);
  if (isBtn) {
    return (
      <CopyToClipboard text={textForCopy ?? text} onCopy={onCopy}>
        <Button type="button" size="medium" Icon={copy ? <CopySVG /> : <CheckSVG />}>
          {copy ? t('Copy') : t('Copied')}
        </Button>
      </CopyToClipboard>
    );
  }
  return (
    <CopyToClipboard text={textForCopy ?? text} onCopy={onCopy}>
      <div className={styles.container}>
        <Copy />
        <span>{copy ? t('Copy') : t('Copied')}</span>
      </div>
    </CopyToClipboard>
  );
}
