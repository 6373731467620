import { useTranslation } from 'react-i18next';
import InputPromo from './InputPromo';
import styles from './PromoCard.module.scss';

export type PromoCardProps = {
  type?: 'farm' | 'nft' | 'farmPro';
  code: string;
  withSharedTabs?: boolean;
  dash?: boolean;
  link: string;
};

export function PromoCard({ type = 'farm', code, link, withSharedTabs, dash }: PromoCardProps) {
  const { t } = useTranslation();
  return (
    <div className={`${styles.container} ${dash && styles.dash}`}>
      {type !== 'farmPro' && <InputPromo label={t('Your Promo Code')} link="" code={code} divider />}
      <InputPromo label={t('Share Promo Code')} link={link} code={code} withTab={withSharedTabs} />
    </div>
  );
}
