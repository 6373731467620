import { Card } from '@shared/Card/Card';
import styles from './EarnignsGraph.module.scss';
import { useCallback } from 'react';
import { Button } from '@shared/Button/Button';
import { MAIN_STATISTIC_URL } from '@utils/links';
import { ArrowRightSmSvg } from '@assets/colorless';
import { Span } from '@shared/Graph/Tabs';
import { customYformat } from '@utils/graph';
import GraphComponent from '@shared/Graph/GraphComponent/GraphComponent';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { rootBrowserGraph } from '@service/reducers/browserStatsReducer';
import { MAIN_CHANGE_DISCREETNESS_EARNING, SET_BROWSER_PERIOD } from '@service/constant';
import { Root } from '@service/reducers';
import { ReactComponent as CoinSvg } from '@assets/coin.svg';
import useIntlFormatter from '@utils/useIntlFormatter';

export default function EarningsGraph() {
  const { t } = useTranslation();
  const { intFormatter } = useIntlFormatter();
  const graphPeriod = useSelector((store: { browserGraph: rootBrowserGraph }) => ({
    start: store.browserGraph.period_start,
    end: store.browserGraph.period_end,
  }));
  const onPeriodChange = useCallback((period: { period_start: string; period_end: string }) => {
    dispatch({
      type: SET_BROWSER_PERIOD,
      ...period,
    });
  }, []);
  const dispatch = useDispatch();
  const period = useSelector((store: Root) => store.mainStats.earningsDiscreteness as Span);
  const data = useSelector((store: Root) => store.mainStats.earnings);
  const total = useSelector((store: Root) => store.mainStats.earningTotal);
  const handleChangePeriod = useCallback(
    (span: Span) => {
      dispatch({
        type: MAIN_CHANGE_DISCREETNESS_EARNING,
        span: span,
      });
    },
    [dispatch]
  );
  const totalEarning = +total ? `${intFormatter(total)} BTC` : undefined;
  return (
    <div className={styles.Earnings_Graph}>
      <div className={styles.body}>
        <GraphComponent
          TotalIcon={<CoinSvg width={32} height={32} style={{ marginRight: '10px' }} />}
          total={totalEarning}
          onPeriodChange={onPeriodChange}
          period={graphPeriod}
          type={'value'}
          onSpanChanged={handleChangePeriod}
          span={period}
          title={t('Earnings Total')}
          data={data}
          tooltip={t('Earnings.tooltip')}
          yFormat={customYformat}
          className={styles.graph}
        />
      </div>
      <div className={styles.footer}>
        <Button
          to={MAIN_STATISTIC_URL}
          iconPosition="right"
          size="medium"
          Icon={<ArrowRightSmSvg />}
          external={false}
          type="link"
        >
          {t('Statistics')}
        </Button>
      </div>
    </div>
  );
}
