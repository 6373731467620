import styles from './BrowserAboutCalc.module.scss';
import { Calc } from './Calc/Calc';
import { CalcModal } from './CalcModal/CalcModal';
import { Card } from '@shared/index';
import { Modal } from '../../../../../shared/Modal';
import { useModal } from '@shared/Modal';

export const BrowserCalc = () => {
  const { opened, onOpen, onClose } = useModal();

  return (
    <Card className={styles.card}>
      <div>
        <Calc onModalCall={onOpen} />
        <Modal.Layout isOpen={opened} onClose={onClose}>
          <CalcModal onClose={onClose} />
        </Modal.Layout>
      </div>
    </Card>
  );
};
