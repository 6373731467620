import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Span } from '@shared/Graph/Tabs';
import { POOL_CHANGE_DISCREETNESS_EARNINGS, SET_BROWSER_PERIOD } from '@service/constant';
import GraphComponent from '@shared/Graph/GraphComponent/GraphComponent';
import { Root } from '@service/reducers';
import { ReactComponent as CoinSvg } from '@assets/coin.svg';
import { customYformat } from '@utils/graph';
import useIntlFormatter from '@utils/useIntlFormatter';

export function PoolEarningsGraph() {
  const { t } = useTranslation();
  const { intFormatter } = useIntlFormatter();
  const dispatch = useDispatch();
  const graphPeriod = useSelector((store: Root) => ({
    start: store.browserGraph.period_start,
    end: store.browserGraph.period_end,
  }));

  const onPeriodChange = useCallback(
    (period: { period_start: string; period_end: string }) => {
      dispatch({
        type: SET_BROWSER_PERIOD,
        ...period,
      });
    },
    [dispatch]
  );

  const period = useSelector((store: Root) => store.poolStatistic.earningsDiscreteness as Span);
  const total = useSelector((store: Root) => store.poolStatistic.earningsTotal);
  const data = useSelector((store: Root) => store.poolStatistic.data.earnings);
  const handleChangePeriod = useCallback(
    (span: Span) => {
      dispatch({
        type: POOL_CHANGE_DISCREETNESS_EARNINGS,
        span: span,
      });
    },
    [dispatch]
  );
  const totalEarning = +total ? intFormatter(total) + ' BTC' : undefined;
  return (
    <GraphComponent
      TotalIcon={<CoinSvg width={32} height={32} style={{ marginRight: '10px' }} />}
      total={totalEarning}
      onPeriodChange={onPeriodChange}
      period={graphPeriod}
      type={'value'}
      onSpanChanged={handleChangePeriod}
      span={period}
      title={t('Earnings')}
      data={data}
      tooltip={t('Earnings.tooltip')}
      yFormat={customYformat}
    />
  );
}
