import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { productURLsMap } from '@constants/productURLsMap';
import { useSelector } from 'react-redux';
import { Root } from '@service/reducers';
import checkPathname from '@utils/checkPathname';
import { PRODUCT_KIND } from '@utils/checkPathname';
import { TextCard } from '../../shared/TextCard/TextCard';

export type PromoTextCardProps = {
  text: string;
};

export function PromoTextCard({ text }: PromoTextCardProps) {
  const { t } = useTranslation();
  const currentLinkId = useSelector((store: Root) => store.user.id);
  const productKind = productURLsMap[checkPathname(window.location.pathname) ?? PRODUCT_KIND.All];
  const cleanedText = t(text).replace(/<a\b[^>]*>(.*?)<\/a>/gi, '$1');

  const formattedUrl =
    productKind === 'https://pool.cryptobrowser.site/'
      ? `${productKind}landing/?aid=${currentLinkId}`
      : `${productKind}${currentLinkId}`;

  const formatTextForCopy = useCallback(() => {
    return `${cleanedText} ${formattedUrl}`;
  }, [t, text, productKind, currentLinkId]);

  const handleCopy = useCallback(
    (e: React.ClipboardEvent<HTMLDivElement>) => {
      e.preventDefault();
      const formattedText = formatTextForCopy();
      e.clipboardData.setData('text/plain', formattedText);
    },
    [formatTextForCopy]
  );

  return (
    <TextCard
      text={text}
      currentLinkId={currentLinkId}
      productKind={productKind}
      handleCopy={handleCopy}
      url={formattedUrl}
      textForCopy={formatTextForCopy()}
    />
  );
}
