import dayjs from 'dayjs';
import { MutableRefObject, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { Bar, BarChart, Brush, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import styles from './Graph.module.scss';
import Tabs, { Span } from './Tabs';
import { useTranslation } from 'react-i18next';
import { DatePicker } from '../DatePicker/DatePicker';
import { GraphLevelsColors } from './Const';
import i18next from 'i18next';
import GraphNoData from './GraphNoData/GraphNoData';
import cls from 'classnames';
import { useBreakpoints } from '@hooks/useBreakpoints';
import { customYformat, defaultXformat, MOB_VISIBLE_BARS, VISIBLE_BARS } from '@utils/graph';
import debounce from 'lodash/debounce';
import useIntlFormatter from '@utils/useIntlFormatter';

export type GraphProps = {
  title?: string;
  yFormat?: (v: any) => string;
  xFormat?: (v: any) => string;
  span: Span;
  dataRef?: MutableRefObject<ReactNode>;
  onSpanChanged: (val: Span) => void;
  AdditionalTab?: ReactNode;
  type?: string;
  withDate?: boolean;
  data: any[];
  period: { start: string; end: string };
  onPeriodChange: (period: { period_start: string; period_end: string }) => void;
  total?: number | string;
  TotalIcon?: ReactNode;
};
const formatData = (data: any[], span: Span, type: string) => {
  if (!data.length) return [];

  const graphData: any[] = [];
  const endTime = dayjs(data[data.length - 1]?.timestamp).valueOf();
  let current = dayjs(data[0]?.timestamp);

  while (current.valueOf() <= endTime) {
    graphData.push({ timestamp: current.valueOf(), [type]: 0 });
    current = current.add(1, span);
  }

  return graphData.map((item) => {
    const foundItem = data.find((ob) => ob.timestamp === item.timestamp);
    return foundItem || item;
  });
};

export function Graph({
  title,
  yFormat,
  span,
  onSpanChanged,
  AdditionalTab,
  dataRef,
  data,
  type = 'value',
  withDate,
  period,
  onPeriodChange,
  total,
  TotalIcon,
}: GraphProps) {
  const { t } = useTranslation();
  const graphContainerRef = useRef<HTMLDivElement>(null);
  const [showShadowLeft, setShowShadowLeft] = useState(false);
  const [showShadowRight, setShowShadowRight] = useState(false);
  const [width, setWidth] = useState<number | string>('100%');
  const graphData = useMemo(() => {
    const currentSpan = span || Span.Day;
    const intervalData: Record<number, number> = {};
    data.forEach((entry) => {
      let intervalStart;
      if (currentSpan === Span.Day) {
        intervalStart = dayjs(entry.timestamp).startOf('day').valueOf();
      } else if (currentSpan === Span.Week) {
        intervalStart = dayjs(entry.timestamp).startOf('week').valueOf();
      } else if (currentSpan === Span.Month) {
        intervalStart = dayjs(entry.timestamp).startOf('month').valueOf();
      }

      if (intervalStart !== undefined) {
        if (intervalData[intervalStart]) {
          intervalData[intervalStart] += entry.value;
        } else {
          intervalData[intervalStart] = entry.value;
        }
      }
    });

    const temp = Object.keys(intervalData).map((timestamp) => ({
      timestamp: Number(timestamp),
      [type]: intervalData[Number(timestamp)],
    }));
    return formatData(temp, currentSpan, type);
  }, [data, type, span]);

  useEffect(() => {
    if (withDate && dataRef) {
      dataRef.current = <DatePicker period={period} onPeriodChange={onPeriodChange} />;
    }
  }, [withDate, dataRef, period, onPeriodChange]);

  const TotalComponent = ({ icon }: { icon?: ReactNode }) => {
    if (icon) {
      return (
        <b className={cls([styles.total_pc, { [styles.totalIcon]: icon }])}>
          {TotalIcon && TotalIcon}
          {total}
        </b>
      );
    } else {
      return <b className={styles.total_pc}>{total}</b>;
    }
  };

  const { isMobile } = useBreakpoints();
  const visibleBars = useMemo(() => (isMobile ? MOB_VISIBLE_BARS : VISIBLE_BARS), [isMobile]);
  const updateShadowVisibility = debounce(() => {
    const container = graphContainerRef.current;
    if (container) {
      const maxScrollLeft = container.scrollWidth - container.clientWidth;

      setShowShadowLeft(container.scrollLeft > 0);
      setShowShadowRight(container.scrollLeft < maxScrollLeft);
    }
  }, 100);

  useEffect(() => {
    const container = graphContainerRef.current;
    if (container) {
      container.scrollLeft = container.scrollWidth;
      updateShadowVisibility();
      if (isMobile) {
        const _width = container?.clientWidth < graphData.length * 60 ? graphData.length * 60 : '100%';
        setWidth(_width);
      }
      container.addEventListener('scroll', updateShadowVisibility);
      return () => container.removeEventListener('scroll', updateShadowVisibility);
    }
  }, [graphData, updateShadowVisibility, isMobile]);

  return (
    <div>
      <div className={styles.top}>
        <Tabs span={span} onSpanChanged={onSpanChanged} />
        {AdditionalTab}
        {total ? <TotalComponent icon={TotalIcon} /> : ''}
      </div>

      {data?.length > 0 ? (
        <div className={styles.graphContainer}>
          <div className={`${styles.shadowOverlayLeft} ${showShadowLeft ? styles.visible : ''}`} />
          <div className={`${styles.shadowOverlayRight} ${showShadowRight ? styles.visible : ''}`} />
          <div className={styles.scrollable} ref={graphContainerRef}>
            <ResponsiveContainer width={width} height={300}>
              <BarChart data={graphData} margin={{ top: 30, right: 30, left: 20, bottom: 5 }}>
                <XAxis
                  dataKey="timestamp"
                  axisLine={{ stroke: '#D6D6D6' }}
                  tickLine={false}
                  tickFormatter={defaultXformat(span)}
                  tick={{ fill: '#757575', fontSize: 11 }}
                />
                {!isMobile && (
                  <YAxis
                    axisLine={false}
                    tickLine={false}
                    tickFormatter={customYformat}
                    tick={{ fill: '#757575', fontSize: 11 }}
                  />
                )}
                <Tooltip
                  cursor={false}
                  content={(props: any) => <CustomTooltip {...props} span={span} title={t(title!)} />}
                  isAnimationActive={false}
                />
                {graphData?.length > visibleBars && !isMobile && (
                  <Brush
                    height={7}
                    stroke="#F7931A"
                    className={styles.brush}
                    travellerWidth={0}
                    rx={4}
                    startIndex={graphData.length - visibleBars}
                    data={graphData}
                    radius={4}
                  />
                )}
                <Bar
                  dataKey={type}
                  fill="#4285F4"
                  maxBarSize={48}
                  isAnimationActive={false}
                  minPointSize={1}
                  style={{ marginBottom: 5 }}
                  radius={4}
                  className={styles.graphBar}
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      ) : (
        <GraphNoData />
      )}
    </div>
  );
}

const Levels = ({ payload, value }: { payload: Array<any>; value: number }) => {
  const { intFormatter } = useIntlFormatter();
  if (payload.length > 1) {
    if (value > 0) {
      return (
        <ul>
          {payload.map(
            (entry, index) =>
              entry.value !== 0 && (
                <li key={`item-${index}`} className={styles.tooltip__item}>
                  <span className={styles.level} style={{ background: GraphLevelsColors[index] }}>
                    {index + 1}
                  </span>
                  <span>{intFormatter(entry.value)}</span>
                </li>
              )
          )}
        </ul>
      );
    } else {
      return <div className={styles.tooltip__value}>{intFormatter(value)}</div>;
    }
  } else {
    return <div className={styles.tooltip__value}>{intFormatter(value)}</div>;
  }
};
const TimeStamp = ({ timestamp, period }: any) => {
  if (period === Span.Day) {
    return <div className={styles.tooltip__date}>{dayjs(timestamp).format('DD.MM.YYYY')}</div>;
  } else if (period === Span.Week) {
    const start = dayjs(timestamp).startOf('week').valueOf();
    const end = dayjs(timestamp).endOf('week').valueOf();
    return (
      <div className={styles.tooltip__date}>
        {dayjs(start).locale(i18next.language).format('DD MMM')} -{' '}
        {dayjs(end).locale(i18next.language).format('DD MMM YYYY')}
      </div>
    );
  } else {
    const start = dayjs(timestamp).startOf('month').valueOf();
    const end = dayjs(timestamp).endOf('month').valueOf();
    return (
      <div className={styles.tooltip__date}>
        {dayjs(start).locale(i18next.language).format('DD MMM')} -{' '}
        {dayjs(end).locale(i18next.language).format('DD MMM YYYY')}
      </div>
    );
  }
};
export const CustomTooltip = (props: any) => {
  const { active, label: timestamp, payload, title, span } = props;
  if (!active) return null;

  let value = payload?.reduce(
    (acc: number, item: any) => (typeof item.value === 'string' ? item.value : acc + item.value),
    0
  );

  if (typeof value === 'number') {
    value = value.toFixed(12).replace(/(\.)?0+$/, '');
  }

  return (
    <div className={styles.tooltip} style={{ pointerEvents: 'auto' }}>
      <TimeStamp timestamp={timestamp} period={span} />
      <div className={styles.tooltip__head}>{title}:</div>
      <Levels payload={payload} value={value} />
    </div>
  );
};
