import styles from './List.module.scss';
import ListItem, { listItem } from '../ListItem/ListItem';
import { data } from './data';

export default function List() {
  const items = data.items as listItem[];
  return (
    <>
      {items.length === 0 ? (
        <div style={{ textAlign: 'center', marginTop: '20px', marginBottom: '20px' }}>No items available</div>
      ) : (
        <div className={styles.list}>
          {items.map((item, index) => (
            <ListItem className={styles.item} key={index} item={item} />
          ))}
        </div>
      )}
    </>
  );
}
