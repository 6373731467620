import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { rootBrowserGraph } from '@service/reducers/browserStatsReducer';
import useFilteredBrowserLinks from '@hooks/useFilteredBrowserLinks';
import MinedView from '@shared/MinedView/MinedView';
import { Root } from '@service/reducers';
import { MindedLevels } from '@shared/MinedView/MinedLevels';
import { BROWSER_STATISTIC } from '@utils/links';

export type BrowserMinedProps = {
  id?: number;
  isShowListId?: boolean;
  isShowButtons?: boolean;
};

export function BrowserMined({ id, isShowListId, isShowButtons }: BrowserMinedProps) {
  const [active, setActive] = useState(`${!!id ? id : 'All Link IDs'}`);
  const filteredLinks = useFilteredBrowserLinks(active);
  const { period_start, period_end } = useSelector((store: Root) => store.browserGraph);
  const browserGraph = useSelector((store: Root) => store.browserGraph);
  const data = useMemo(
    () =>
      'All Link IDs' !== active
        ? (browserGraph.levels[active] as MindedLevels) || Array(3).fill({ ref: 0, mined: 0 })
        : (browserGraph.levels.total as MindedLevels),
    [active, browserGraph.levels]
  );

  const total = useMemo(
    () =>
      data?.reduce(
        (acc, item) => ({
          ref: acc.ref + item.ref,
          mined: acc.mined + item.mined,
        }),
        { ref: 0, mined: 0 }
      ),
    [data, id]
  );
  return (
    <MinedView
      id={id}
      info={filteredLinks}
      isLoaded={true}
      active={active}
      setActive={setActive}
      isShowListId={isShowListId}
      start={period_start}
      end={period_end}
      total={total}
      data={data}
      isOpensLink={`${BROWSER_STATISTIC}/#open`}
      isInstallLink={`${BROWSER_STATISTIC}/#install`}
      isMinerLink={`${BROWSER_STATISTIC}/#miner`}
      buttonLink={BROWSER_STATISTIC}
      isShowButtons={isShowButtons}
    />
  );
}
