import { Modal } from '../Modal';
import { HshSvg, NcwLogo } from '@assets/colored';
import { Button, ButtonVariant } from '@shared/Button/Button';
import React, { ReactElement, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import bgHshMobile from '@assets/bg-hsh-mobile.png';
import bgHshMobileRetina from '@assets/bg-hsh-mobile@2x.png';
import bgHshLeft from '@assets/bg-hsh-left.png';
import bgHshLeftRetina from '@assets/bg-hsh-left@2x.png';
import bgHshRight from '@assets/bg-hsh-right.png';
import bgHshRightRetina from '@assets/bg-hsh-right@2x.png';
import { useBreakpoints } from '@hooks/useBreakpoints';
import styles from './ModalHSHLayout.module.scss';
import { CrossCircleSvg } from '@assets/colorless';

interface buttonType {
  icon?: ReactElement;
  text: string;
}

interface buttonLinkType extends buttonType {
  type: 'link';
  link: string;
}

interface buttonButtonType extends buttonType {
  type: 'button';
  onClick: () => void;
}

interface ModalHSHLayoutProps {
  opened: boolean;
  onClose: () => void;
  title: string | ReactNode;
  text: string | ReactNode;
  isBackButton?: boolean;
  button: buttonButtonType | buttonLinkType;
  unShow?: {
    handleDontShow: () => void;
    text: string;
  };
  isDecoration?: boolean;
  isNCW?: boolean;
}

export default function ModalHSHLayout({
  opened,
  onClose,
  title,
  text,
  isBackButton,
  button,
  unShow,
  isDecoration,
  isNCW,
}: ModalHSHLayoutProps) {
  const { t } = useTranslation();
  const { isMobile } = useBreakpoints();

  return (
    <Modal.Layout onClose={onClose} isOpen={opened}>
      <Modal.Content>
        <Modal.Body>
          <Modal.Icon icon={isNCW ? <NcwLogo /> : <HshSvg />} />
          <Modal.TitleDescription title={title} description={text} />
        </Modal.Body>
        <Modal.Footer>
          {isBackButton && (
            <Button type={'button'} onClick={onClose} variant={ButtonVariant.Default} Icon={<CrossCircleSvg />}>
              {t('Cancel')}
            </Button>
          )}
          {button.type === 'link' ? (
            <Button type={'link'} to={button.link} variant={ButtonVariant.Primary} Icon={button.icon}>
              {button.text}
            </Button>
          ) : (
            <Button type={'button'} onClick={button.onClick} variant={ButtonVariant.Primary} Icon={button.icon}>
              {button.text}
            </Button>
          )}
        </Modal.Footer>

        {unShow && (
          <div className={styles.modalHSH__unshow}>
            <input id="modalHSHCheckbox" type="checkbox" onClick={unShow?.handleDontShow} />
            <label htmlFor={'modalHSHCheckbox'}>{unShow?.text}</label>
          </div>
        )}

        {isDecoration && (
          <div className={styles.modalHSH__decoration}>
            {isMobile ? (
              <img
                src={bgHshMobile}
                srcSet={bgHshMobileRetina}
                className={styles.decoration__mobile}
                alt="background"
              />
            ) : (
              <>
                <img src={bgHshLeft} srcSet={bgHshLeftRetina} className={styles.decoration__left} alt="background" />
                <img src={bgHshRight} srcSet={bgHshRightRetina} className={styles.decoration__right} alt="background" />
              </>
            )}
          </div>
        )}
      </Modal.Content>
    </Modal.Layout>
  );
}
