import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import getPromos from '@service/actions/getPromos';
import Loader from '@shared/Loader';
import { HelmetHead } from '../headersComponents/HelmetHead';
import CommonAboutView from './view/CommonAboutView';
import getBrowserLand from '@service/actions/getBrowserLand';
import { getPoolLandings } from '@service/actions/getPoolLandings';
import { Root } from '@service/reducers';
import { AllSubheader } from '@pages/headersComponents/AllSubheader';
import useProductCardsInfo from '@hooks/useProductCardsInfo';
import getBalances from '@service/actions/getBalances';

export default function CommonAbout() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isAuth = useSelector((store: Root) => store.user.tokens);
  const store = useSelector((store: Root) => store);
  useEffect(() => {
    if (store.user.hasRequested) {
      !store.balance.get_balance && dispatch(getBalances());
      !store.promo.promo_get && !store.promo.promo_request && dispatch(getPromos());
      !store.browserPromo.land_get && !store.browserPromo.land_request && dispatch(getBrowserLand());
      !store.poolLandings.poolLandsGet && !store.poolLandings.poolLandsGet && dispatch(getPoolLandings());
    }
  }, [dispatch, store.user.tokens, store.user.hasRequested]);

  const data = useProductCardsInfo();
  if (store.user.request) {
    return <Loader />;
  }
  return (
    <>
      <HelmetHead title={t('Overview')} />
      <AllSubheader />
      <CommonAboutView {...data} isAuth={Boolean(isAuth)} />
    </>
  );
}
