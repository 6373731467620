import CommonDashboardView from '@pages/CommonDashboard/view/CommonDashboardView';
import { useDispatch, useSelector } from 'react-redux';
import { Root } from '@service/reducers';
import Loader from '@shared/Loader';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import getPromos from '@service/actions/getPromos';
import getBrowserLand from '@service/actions/getBrowserLand';
import { getPoolLandings } from '@service/actions/getPoolLandings';
import { HelmetHead } from '@pages/headersComponents/HelmetHead';
import { useTranslation } from 'react-i18next';
import { useNewsLoad } from '@hooks/useNewsLoad';
import { AllSubheader } from '@pages/headersComponents/AllSubheader';
import useProductCardsInfo from '@hooks/useProductCardsInfo';
import {
  selectBrowserGraph,
  selectBrowserPromo,
  selectPoolLandings,
  selectPromo,
  selectUser,
} from '@service/selectors/selectors';
import { LinkAcc, rootBalanceType } from '@service/reducers/balanceReducer';
import getBalances from '@service/actions/getBalances';
import getFarmMiner from '@service/actions/getFarmMiner';
import { Span } from '@shared/Graph/Tabs';
import getFarmEarnings from '@service/actions/getFarmEarnings';
import { FarmPromoTabs } from '@shared/FarmPromoCodes/FarmPromoCodesTabs';
import { farmLinks } from '@shared/FarmPromoCodes/FarmPromoCodes';
import getMainInfo from '@service/actions/getMainInfo';
import getAggregatedFarmData from '@service/actions/getAggregatedFarmData';

export default function CommonDashboard() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const storeMain = useSelector((store: Root) => store.mainStats);
  const store = useSelector((store: Root) => store.browserGraph);
  const promo = useSelector((store: Root) => selectPromo(store));
  const poolLandings = useSelector((store: Root) => selectPoolLandings(store));
  const user = useSelector((store: Root) => selectUser(store));
  const browserPromo = useSelector((store: Root) => selectBrowserPromo(store));
  const browserGraph = useSelector((store: Root) => selectBrowserGraph(store));
  const balance = useSelector((store: Root) => store.balance);
  const links = useSelector((store: { balance: rootBalanceType }) => store.balance.links);
  const isFirstLoad = useRef(true);
  useNewsLoad();
  const data = useProductCardsInfo();

  useEffect(() => {
    if (user.hasRequested) {
      if (!promo.promo_get && !promo.promo_request) {
        dispatch(getPromos());
      }
      if (!browserPromo.land_get && !browserPromo.land_request) {
        dispatch(getBrowserLand());
      }
      if (!poolLandings.poolLandsGet && !poolLandings.poolLandsGet) {
        dispatch(getPoolLandings());
      }
    }
  }, [dispatch, user.hasRequested, promo.promo_get, browserPromo.land_get, poolLandings.poolLandsGet]);

  useEffect(() => {
    user.id !== 0 && dispatch(getFarmMiner(Span.Day, user.id, browserGraph.period_start, browserGraph.period_end));
    user.id !== 0 && dispatch(getFarmEarnings(Span.Day, user.id, browserGraph.period_start, browserGraph.period_end));
  }, [dispatch, browserGraph.period_start, browserGraph.period_end]);
  useEffect(() => {
    if (!isFirstLoad.current) {
      dispatch(getMainInfo(store.period_start, store.period_end, storeMain.earningsDiscreteness, true));
    }
  }, [storeMain.earningsDiscreteness]);

  useEffect(() => {
    dispatch(getMainInfo(store.period_start, store.period_end, storeMain.earningsDiscreteness, false));
    isFirstLoad.current = false;
  }, [store.period_start, store.period_end]);

  useEffect(() => {
    user.id !== 0 && dispatch(getAggregatedFarmData(Span.Day, user.id, store.period_start, store.period_end));
  }, [dispatch, store.period_start, store.period_end]);

  const count = useMemo(() => balance.links.filter((i) => i.type !== 'farm').length * 2 + 1, [balance.links]);

  const linksInfo = {
    farm: [
      {
        id: user.id,
        name: user.id,
        comment: null,
        is_banned: false,
        type: null,
        url: farmLinks[FarmPromoTabs.Usually],
      },
    ] as unknown as LinkAcc[],
    browser: links,
    ct_pool: links,
    count,
    total: {
      link: 'https://cryptobrowser.site/',
      open: 12,
      install: 12,
      active: 12,
      revenue: 0.00000031,
    },
  };

  const handleRefresh = useCallback(() => {
    dispatch(getBalances());
    dispatch(getMainInfo(store.period_start, store.period_end, storeMain.earningsDiscreteness));
  }, [dispatch, store.period_start, store.period_end, storeMain.earningsDiscreteness]);

  const isLoading = !browserGraph.stats_links_get && !balance.get_balance;

  if (!user.hasRequested) {
    return <Loader />;
  }

  return (
    <>
      <HelmetHead title={t('Overview')} />
      <AllSubheader />
      <CommonDashboardView
        {...data}
        linksInfo={linksInfo}
        onLinkCreate={() => {}}
        isLoading={isLoading}
        handleRefresh={handleRefresh}
      />
    </>
  );
}
