import styles from './WithdrawalModal.module.scss';
import { WithdrawalModalState } from '../WithdrawalView';
import { ModalError } from './ModalError';
import { ModalConfirm } from './ModalConfirm';
import { ModalWallet } from '../PromoModals/ModalWallet';
import { PromoModalWithBotAndCheck } from '../PromoModals/PromoModalWithBotAndCheck';
import ModalChange from './ModalChange';
import { usePromoVariant } from '@hooks/usePromoVariant';
import { ModalNetworkAlert } from './ModalNetworkAlert';
import cls from 'classnames';
import { useCallback } from 'react';
import { Modal } from '../../../../shared/Modal';

import { useModal } from '@shared/Modal';

export type WithdrawalModalProps = {
  handleModalClose: () => void;
  variant: WithdrawalModalState;
  onResolveChange: () => void;
  isBitcoin: boolean;
};

export function WithdrawalModal({ handleModalClose, variant, onResolveChange, isBitcoin }: WithdrawalModalProps) {
  const promoInfo = usePromoVariant();
  const { opened, onClose } = useModal(true);
  const handleClose = useCallback(() => {
    if (variant === WithdrawalModalState.Promo || variant === WithdrawalModalState.NetworkAlert) {
      return null;
    }
    handleModalClose();
    onClose();
  }, [onClose, variant]);

  return (
    <Modal.Layout isOpen={opened} onClose={handleClose}>
      {variant === WithdrawalModalState.Error && <ModalError onClose={handleClose} />}
      {variant === WithdrawalModalState.Confirm && <ModalConfirm isBitcoin={isBitcoin} />}
      {variant === WithdrawalModalState.Wallet && <ModalWallet onClose={handleClose} />}
      {variant === WithdrawalModalState.Change && (
        <ModalChange onClose={handleClose} onResolveChange={onResolveChange} />
      )}
      {variant === WithdrawalModalState.Promo && <PromoModalWithBotAndCheck onClose={handleClose} {...promoInfo} />}
      {variant === WithdrawalModalState.NetworkAlert && <ModalNetworkAlert onClose={handleClose} />}
    </Modal.Layout>
  );
}
