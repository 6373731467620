import { Modal } from '@shared/Modal';
import { ModalNetworkAlert } from '../../../../Withdrawal/view/WithdrawalModals/ModalNetworkAlert';

type ModalProps = {
  onClose: () => void;
};

export function ModalNetworkError({ onClose }: ModalProps) {
  return (
    <Modal.Layout isOpen={true} onClose={onClose}>
      <ModalNetworkAlert onClose={onClose} />
    </Modal.Layout>
  );
}
