import { MAIN_LINKS_REQUEST, MAIN_CLICKS_SUCCESS, MAIN_LINKS_SUCCESS, MAIN_LINKS_ERROR } from '@service/constant';
import { AppDispatch } from '../..';
import { Discreteness } from '../../backend/JsonRpcApi';
import { rpc } from '../../backend/Rpc';
import { Span } from '@shared/Graph/Tabs';

export default function getStatsClicks(
  period_start: string,
  period_end: string,
  discreteness: Discreteness = Span.Day
) {
  return async function (dispatch: AppDispatch) {
    try {
      dispatch({ type: MAIN_LINKS_REQUEST });
      const res = await rpc.transmit('statistics.v2.clicks', {
        discreteness: discreteness,
        end_date: period_end,
        start_date: period_start,
      });
      const updateOpens = res
        .map((item) => ({
          timestamp: item.timestamp * 1000,
          account_id: item.advert_id,
          value: item.value,
        }))
        .sort((a, b) => a.timestamp - b.timestamp);
      dispatch({ type: MAIN_CLICKS_SUCCESS, clicks: updateOpens });

      dispatch({ type: MAIN_LINKS_SUCCESS });
    } catch (e) {
      dispatch({ type: MAIN_LINKS_ERROR });
      console.log(e);
    }
  };
}
