import styles from './CBClickBanner.module.scss';
import { ReactComponent as Logo } from '@assets/cbclick.svg';
import screen from '@assets/cbclick-screen.png';
import screen2x from '@assets/cbclick-screen-retina.png';
import { useTranslation } from 'react-i18next';
import { Button, ButtonVariant } from '@shared/index';
import { useState } from 'react';
import { CrossSvg } from '@assets/colorless';

export default function CBClickBanner() {
  const { t } = useTranslation();
  const [show, setShow] = useState(true);
  if (!show) {
    return null;
  }
  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <CrossSvg className={styles.cross} onClick={() => setShow((prev) => !prev)} />
        <div className={styles.new}>{t('new')}</div>
        <div className={styles.content}>
          <Logo className={styles.logo} />
          <div className={styles.title}>{t('Make money on short links')}</div>
          <p className={styles.text}>
            {t(
              'Try a new, even easier way to earn with CryptoTab! Create shortened links, share across the web, and get paid when users follow it. Your shortened links statistics will be displayed in the "Your Links" section of the Affiliate dashboard.'
            )}
          </p>
          <Button type={'link'} external to="https://cb.click/" variant={ButtonVariant.Primary} size="medium">
            {t('VIEW DETAILS')}
          </Button>
        </div>
        <img src={screen} srcSet={screen2x + ' 2x'} className={styles.screen} alt="notebook" />
      </div>
    </section>
  );
}
