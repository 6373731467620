import { Trans, useTranslation } from 'react-i18next';
import React, { useCallback, useEffect } from 'react';
import { externalLinks } from '@utils/externalLinks';
import { ModalsHSHType } from '../index';
import ModalHSHLayout from '@shared/ModalHSHLayout';
import { BROWSER_WITHDRAWAL } from '@utils/links';
import styles from '@shared/ModalHSHLayout/ModalHSHLayout.module.scss';
import { OpenLinkSvg } from '@assets/colorless';
import { useModal } from '@shared/Modal';

const ONE_WEEK = 604_800_000;

export function ModalInfoHSH({ balance }: ModalsHSHType) {
  const { t } = useTranslation();
  const { opened, onOpen, onClose } = useModal(false);

  const openModal = useCallback(() => {
    const localDate = localStorage.getItem('lastVisit') || '{}';
    const lastVisit = JSON.parse(localDate);
    const isHasVisit = lastVisit.date;

    if ((isHasVisit && !lastVisit.isShow) || +balance > 100) {
      return onClose();
    } else if (!isHasVisit || Date.now() - lastVisit.date >= ONE_WEEK) {
      onOpen();
    }

    const visit = { date: Date.now(), isShow: true };
    localStorage.setItem('lastVisit', JSON.stringify(visit));
  }, [balance]);

  useEffect(() => {
    openModal();
  }, [balance]);

  const handleDontShow = useCallback(() => {
    const localDate = localStorage.getItem('lastVisit') || '{}';
    const lastVisit = JSON.parse(localDate);
    const visit = { ...lastVisit, isShow: !lastVisit.isShow };
    localStorage.setItem('lastVisit', JSON.stringify(visit));
  }, []);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <ModalHSHLayout
      opened={opened}
      onClose={handleClose}
      title={t('HSH_Upd.HSH_about.Title')}
      text={
        <Trans
          i18nKey="HSH_Upd.HSH_about.Description"
          components={{
            b: <b />,
            a: <a href={externalLinks.ncw} className={styles.link} target="_blank" rel="noreferrer" />,
          }}
        />
      }
      button={{
        type: 'link',
        icon: <OpenLinkSvg />,
        text: t('More details'),
        link: BROWSER_WITHDRAWAL,
      }}
      unShow={{
        handleDontShow: handleDontShow,
        text: t('Don’t show this window again'),
      }}
      isDecoration={true}
    />
  );
}
