import { CSSProperties, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styles from './Balance.module.scss';
import coin from '@assets/coin.svg';
import { Button, ButtonVariant } from '@shared/index';
import { ReactComponent as TransferIcon } from '@assets/colorless/transfer.svg';
import { ReactComponent as Refresh } from '@assets/colorless/refresh-payment.svg';
import { useDispatch, useSelector } from 'react-redux';
import getBalances from '@service/actions/getBalances';
import { HshSvg } from '@assets/colored';
import { InfoSvg, LockSvg, HelpSvg } from '@assets/colorless';
import BtcRate from '../BtcRate';
import Reel from 'react-reel';
import theme from './theme.module.scss';
import { BROWSER_BALANCE, BROWSER_WITHDRAWAL } from '@utils/links';
import { Root } from '@service/reducers';
import cls from 'classnames';
import { CHANGE_IS_SHOWING_HSH_INCOMING } from '@service/constant';
import { jsColors } from '@utils/jsColors';
import { convertBigValueToLocale } from '@utils/convertBigValueToLocale';
import { useBreakpoints } from '@hooks/useBreakpoints';
import useIntlFormatter from '@utils/useIntlFormatter';

export enum BalanceVariant {
  Affiliate,
  Farm,
  NFT,
  POOL,
  HSH,
  All,
}

export type BalanceProps = {
  variant: BalanceVariant;
};

export default function Balance({ variant }: BalanceProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const balance = useSelector((store: Root) => store.balance);
  const rate = useSelector((store: Root) => store.rate);
  const { isMobile } = useBreakpoints();

  const changeIsShowHshInc = useCallback(() => {
    dispatch({ type: CHANGE_IS_SHOWING_HSH_INCOMING });
  }, []);

  useEffect(() => {
    !balance.get_balance && !balance.balance_request && !balance.balance_error && dispatch(getBalances());
  }, [dispatch, balance.get_balance, balance.balance_request, balance.balance_error]);

  useEffect(() => {
    return changeIsShowHshInc;
  }, [changeIsShowHshInc]);

  const { intFormatter } = useIntlFormatter();

  const cardVariants = useMemo(
    () =>
      variant === BalanceVariant.Farm
        ? {
            title: 'CT FARM',
            minedTitle: t('Mined in FARM:'),
            affTitle: t('FARM AFFILIATE'),
            totalTitle: t('TOTAL FARM EARNINGS:'),
            mined: balance.farm_balance.mined,
            referral: balance.farm_balance.referral,
            total: balance.farm_balance.total,
            balance: balance.farm_balance.balance,
          }
        : variant === BalanceVariant.Affiliate
        ? {
            title: 'Cryptotab',
            minedTitle: t('MINED IN BROWSER:'),
            affTitle: t('BROWSER AFFILIATE'),
            totalTitle: t('TOTAL BROWSER EARNINGS:'),
            mined: balance.aff_balance.mined,
            referral: balance.aff_balance.referral,
            total: balance.aff_balance.total,
            balance: balance.aff_balance.balance,
          }
        : variant === BalanceVariant.NFT
        ? {
            title: 'CT NFT',
            totalTitle: t('NFT_Affiliate_Earnings_Block'),
            total: balance.nft_balance.total,
            balance: balance.nft_balance.balance,
          }
        : variant === BalanceVariant.POOL
        ? {
            title: 'CT POOL',
            nftTitle: t('NFT_Mining'),
            poolTitle: t('Pool Mining'),
            totalTitle: t('Affiliate_income'),
            pool: balance.pool_balance.pool,
            nft: balance.pool_balance.nft,
            total: balance.pool_balance.affiliate,
            balance: balance.pool_balance.balance,
          }
        : variant === BalanceVariant.HSH
        ? {
            hshTitle: t('HSH_Upd.Dash.HSH_balance'),
            affTitle: t('Affiliate Total:'),
            totalTitle: t('Total Earnings:'),
            hshCurrent: balance.hsh_balance.current,
            hshIncrement: balance.increased_balance_hsh.totalAmount,
            hshIsShowingInc: balance.increased_balance_hsh.isShowing,
            hshAccrued: balance.hsh_balance.accrued,
            hshPending: balance.hsh_balance.income,
            promptHsh: [
              <Trans
                i18nKey="HSH.Interf.Info.Balance"
                components={{ b: <b /> }}
                values={{ HSH: convertBigValueToLocale(balance.hsh_balance.current, false, 2) }}
              />,
              <Trans
                i18nKey="HSH.Interf.Info.Pending"
                components={{ b: <b /> }}
                values={{ HSH: convertBigValueToLocale(balance.hsh_balance.income, false, 2) }}
              />,
              <>
                {balance.hsh_balance.income > 0 && (
                  <span className={styles.prompt__next}>
                    <b>{t('Next_confirm_in')} </b>
                    {balance.hsh_balance?.nextPayment?.days > 0 &&
                      t('Next_conf.Days', { days: balance.hsh_balance?.nextPayment?.days })}{' '}
                    {balance.hsh_balance?.nextPayment?.hours > 0 &&
                      t('Next_conf.Hours', { hours: balance.hsh_balance?.nextPayment?.hours })}{' '}
                    {balance.hsh_balance?.nextPayment?.mins > 0 &&
                      t('Next_conf.Min', { minutes: balance.hsh_balance?.nextPayment?.mins })}
                  </span>
                )}
              </>,
              <Trans
                i18nKey="HSH.Interf.Info.Total"
                components={{ b: <b /> }}
                values={{ HSH: convertBigValueToLocale(balance.hsh_balance.accrued) }}
              />,
            ],
            referral: balance.all_balance.referral,
            total: balance.all_balance.total,
            balance: balance.all_balance.balance,
          }
        : {
            title: t('Your balance'),
            minedTitle: t('Mined Total:'),
            affTitle: t('Affiliate Total:'),
            totalTitle: t('Total Earnings:'),
            mined: balance.all_balance.mined,
            referral: balance.all_balance.referral,
            total: balance.all_balance.total,
            balance: balance.all_balance.balance,
          },
    [variant, t, balance]
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.section}>
        <div className={styles.section__header}>
          <span>{t('Your balance')}</span>
          <Link to={`${BROWSER_BALANCE}/${balance.isBitcoin ? 'BTC' : 'HSH'}`} className={styles.section__header_link}>
            <span className={styles.section__header_icon}>
              <Refresh />
            </span>
            {t('Payment History')}
          </Link>
        </div>
        <div className={styles.section__mob_container}>
          <div className={styles.balance}>
            <div className={styles.balance__btc}>
              <img width="80" height="80" src={coin} alt="coin" />
              <span className={styles.odometer}>
                {cardVariants.balance !== 0 ? (
                  <Reel
                    text={intFormatter(cardVariants.balance, { minimumFractionDigits: 8, maximumFractionDigits: 8 })}
                    theme={theme}
                  />
                ) : (
                  <Reel text="0.00000000" theme={theme} />
                )}{' '}
              </span>
              <span className={styles.balance__currencie}>&nbsp;BTC</span>
            </div>
            <div className={styles.balance__usd}>
              {isMobile ? (
                <>
                  $
                  {cardVariants.balance
                    ? intFormatter((+cardVariants.balance * +rate.btc2usd).toFixed(2))
                    : Number(0).toFixed(2)}{' '}
                  USD
                </>
              ) : (
                <>
                  {t('Approx.')}{' '}
                  <b>
                    USD{' '}
                    {cardVariants.balance
                      ? intFormatter((+cardVariants.balance * +rate.btc2usd).toFixed(2))
                      : Number(0).toFixed(2)}
                  </b>
                </>
              )}
            </div>
          </div>
          <div className={styles.row}>
            <BtcRate />
          </div>
        </div>
        <div className={styles.info}>
          <div className={styles.info__mob_container}>
            {variant === BalanceVariant.HSH && (
              <>
                <Info
                  title={cardVariants.hshTitle!}
                  balance={cardVariants.hshCurrent!}
                  balanceLocked={cardVariants.hshPending!}
                  hshIncrement={cardVariants.hshIncrement!}
                  hshIsShowingInc={cardVariants.hshIsShowingInc!}
                  promptHsh={cardVariants.promptHsh!}
                  btc2usd={rate.btc2usd}
                  hsh2usd={rate.hsh2usd!}
                  isHSH={true}
                />
                <Info title={cardVariants.affTitle!} balance={cardVariants.referral!} btc2usd={rate.btc2usd} />
              </>
            )}
            {variant !== BalanceVariant.NFT && variant !== BalanceVariant.POOL && variant !== BalanceVariant.HSH && (
              <>
                <Info title={cardVariants.minedTitle!} balance={cardVariants.mined!} btc2usd={rate.btc2usd} />
                <Info title={cardVariants.affTitle!} balance={cardVariants.referral!} btc2usd={rate.btc2usd} />
              </>
            )}
            <Info
              title={cardVariants.totalTitle}
              balance={cardVariants.total}
              btc2usd={rate.btc2usd}
              data={variant === BalanceVariant.NFT ? 'nft' : undefined}
              style={{ borderRight: 'none', justifyContent: 'flex-start' }}
            />
          </div>
          <div className={cls([styles.info__item, styles.info__item_withdraw])}>
            <Button
              type="link"
              to={{ pathname: `${BROWSER_WITHDRAWAL}/${balance.isBitcoin ? 'BTC' : 'HSH'}` }}
              className={styles.btn}
              variant={ButtonVariant.Primary}
              Icon={<TransferIcon />}
            >
              <span>{t('Withdraw')}</span>
            </Button>
            <Link
              to={`${BROWSER_BALANCE}/${balance.isBitcoin ? 'BTC' : 'HSH'}`}
              className={cls([styles.section__header_link, styles.linkMob])}
            >
              <Refresh />
            </Link>
          </div>
        </div>
      </div>
      <div className={cls([styles.info__item, styles.info__item_withdraw, styles.info__item_withdraw_mob])}>
        <Button
          type="link"
          to={{ pathname: BROWSER_WITHDRAWAL }}
          variant={ButtonVariant.Text}
          className={styles.btn}
          size="medium"
          Icon={<TransferIcon />}
        >
          <span>{t('Withdraw')}</span>
        </Button>
        <Link
          to={`${BROWSER_BALANCE}/${balance.isBitcoin ? 'BTC' : 'HSH'}`}
          className={cls([styles.section__header_link, styles.linkMob])}
        >
          <Refresh />
        </Link>
      </div>
    </div>
  );
}

type InfoType = {
  title: string;
  btc2usd: number;
  hsh2usd?: number;
  balance: string | number;
  balanceLocked?: string | number;
  hshIncrement?: string | number;
  hshIsShowingInc?: boolean;
  promptHsh?: ReactNode[];
  style?: CSSProperties;
  data?: string;
  isHSH?: boolean;
};

const Info = ({
  title,
  btc2usd,
  balance,
  hsh2usd,
  balanceLocked,
  hshIncrement,
  hshIsShowingInc,
  promptHsh,
  isHSH,
  style,
  data,
}: InfoType) => {
  const [isOpacity, setIsOpacity] = useState(true);
  const [isIncomeHSH, setIsIncomeHSH] = useState(false);

  const { intFormatter } = useIntlFormatter();

  useEffect(() => {
    let timerId: NodeJS.Timeout | undefined;
    if (hshIncrement) {
      setIsIncomeHSH(true);

      timerId = setTimeout(() => {
        setIsIncomeHSH(false);
      }, 10000);
    }

    return () => clearTimeout(timerId);
  }, [hshIncrement, hshIsShowingInc]);

  const switchOpacity = () => {
    setIsOpacity((prev) => !prev);
  };

  return (
    <div className={styles.info__item} data-hook={data} style={style}>
      <div className={styles.col}>
        <div className={styles.info__label}>
          <span>{title}</span>
          {isHSH && (
            <div className={styles.info__prompt}>
              <HelpSvg className={styles.prompt__svg} color={jsColors.blue} />
              <div className={cls(styles.prompt__container, isOpacity && styles['prompt__container--opacity'])}>
                {promptHsh &&
                  promptHsh.map((text, id) => {
                    return <span key={id}>{text}</span>;
                  })}
              </div>
            </div>
          )}
        </div>
        <div className={styles.info__mob_row}>
          <div className={styles.info__coin}>
            {isHSH ? (
              <div className={styles.coin__container}>
                <HshSvg className={styles.coin__svg} />
                <div className={styles.coin__balanceWrapper}>
                  <span className={styles.coin__balance}>
                    {balance ? convertBigValueToLocale(balance, false, 2) : Number(0).toFixed(2)}{' '}
                    <span className={styles.coin__currencie}>HSH</span>
                  </span>
                  {hshIncrement && hshIsShowingInc ? (
                    <span className={cls(styles.coin__increment, isIncomeHSH && styles['coin__increment--show'])}>
                      +{convertBigValueToLocale(hshIncrement)}
                    </span>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            ) : (
              <span className={styles.coin__balance}>
                {balance ? intFormatter(balance) : Number(0).toFixed(8)}{' '}
                <span className={styles.coin__currencie}>BTC</span>
              </span>
            )}
          </div>
          <div className={styles.info__usd}>
            <div className={styles.usd__rate}>
              <span>$</span>
              {isHSH
                ? balance
                  ? intFormatter((+balance * hsh2usd!).toFixed(2))
                  : Number(0).toFixed(2)
                : balance
                ? intFormatter((+balance * btc2usd).toFixed(2))
                : Number(0).toFixed(2)}
              <span> USD</span>
            </div>
            {isHSH && balanceLocked !== undefined && +balanceLocked > 0 && (
              <div className={styles.usd__locked} onMouseEnter={switchOpacity} onMouseLeave={switchOpacity}>
                <LockSvg className={styles.usd__svg} />
                <span>{convertBigValueToLocale(balanceLocked)} HSH</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
