export const formatAddress = (adr: string, isMobile?: boolean) => {
  let max = 10;
  let min = 7;

  const atIndex = adr.indexOf('@');
  if (atIndex !== -1) {
    const localPart = adr.slice(0, atIndex);
    const domainPart = adr.slice(atIndex);

    if (localPart.length >= 9) {
      return `${localPart.slice(0, 3)}...${localPart.slice(-3)}${domainPart}`;
    }

    return adr;
  }

  if ((adr.length <= 25 && !isMobile) || (adr.length <= 10 && isMobile)) return adr;

  return `${adr.slice(0, isMobile ? min : max)}...${adr.slice(isMobile ? -min : -max)}`;
};
